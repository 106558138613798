import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { AppState } from '../../../../reducers';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { CanvasActions } from '../../../../actions';
import { BackgroundImageElement, CanvasCoordinate, CanvasDimensions, Color, Trim, TrimTypes } from '../../../../models';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageElement } from '../../../../models';
import { ConfigService, GetTextService } from '../../../../services';
import * as fromPermissions from '../../../../reducers/permissions.reducer';
import { Translate } from '../../../../actions/canvas-actions';
import {
  getCoordinatesAfterResize,
  MAX_ELEMENT_SIZE_FACTOR,
  MIN_ELEMENT_SIZE_FACTOR
} from '../../../../utils/element.utils';
import { getShowTrimOption, getShowBackgroundColorOption } from '../../../../config/reducer';
import { map } from 'rxjs/operators';
import { selectDesign } from '../../../../selectors';
import * as FabricCanvasActions from "../../../design/canvas/actions";

/**
 * Component for edit options when a background image is selected.
 */

@Component({
  selector: 'ed-edit-background',
  templateUrl: 'edit-background.component.html',
  styleUrls: ['../../edit.component.scss', '../../../../shared/button/buttons.scss', './edit-background.component.scss']
})
export class EditBackgroundComponent implements OnInit, OnDestroy {
  showDimensionsInputField$: Observable<boolean>;
  canEditSettings$: Observable<boolean>;

  _backgroundImage: ImageElement | BackgroundImageElement;

  designSubscription$: Subscription;

  showTrimOption$: Observable<boolean>;
  showBackgroundColorOption$: Observable<boolean>;
  selectedTrimType: TrimTypes;
  trimOptions$: Observable<Trim[]>;

  @Input() showMoreOptions = false;
  @Output() showMoreOptionsChange = new EventEmitter<boolean>();

  @Input() set backgroundImage(backgroundImage: ImageElement | BackgroundImageElement) {
    this._backgroundImage = backgroundImage;
  }

  get backgroundImage() {
    return this._backgroundImage;
  }

  @Input() backgroundColor: Color;
  @Input() closeColorDialog: boolean;
  @Input() dataLayerIdPrefix: string;

  @Input() pageWidth: number;
  @Input() pageHeight: number;
  readonly maxElementSizeFactor = MAX_ELEMENT_SIZE_FACTOR;
  readonly minElementSizeFactor = MIN_ELEMENT_SIZE_FACTOR;

  @Output() chooseColor: EventEmitter<Color> = new EventEmitter();

  get backgroundImageColor(): Color {
    return new Color('', this.backgroundImage.color);
  }

  constructor(
    public store: Store<AppState>,
    protected sanitizer: DomSanitizer,
    public getTextService: GetTextService,
    public configService: ConfigService
  ) {}

  ngOnInit() {
    this.showDimensionsInputField$ = this.store.pipe(select(fromPermissions.getHasDimensionsInputField));
    this.canEditSettings$ = this.store.pipe(select(fromPermissions.canEditElementSettings));

    this.designSubscription$ = this.store.pipe(select(selectDesign)).subscribe(design => {
      this.selectedTrimType = design.trimType;
    });

    this.trimOptions$ = this.store.pipe(select(s => s.config.trimData));

    this.showTrimOption$ = combineLatest([
      this.store.pipe(select(fromPermissions.getFunctionPermissions)),
      this.store.pipe(select(getShowTrimOption))
    ]).pipe(map(([permissions, showTrim]) => permissions.canChangeBorderTrim && showTrim));

    this.showBackgroundColorOption$ = combineLatest([
      this.store.pipe(select(fromPermissions.getFunctionPermissions)),
      this.store.pipe(select(getShowBackgroundColorOption))
    ]).pipe(map(([permissions, showBackgroundColor]) => permissions.canChangeBackgroundColor && showBackgroundColor));
  }

  ngOnDestroy() {
    this.designSubscription$.unsubscribe();
  }

  fillHorizontal() {
    this.store.dispatch(new CanvasActions.FillHorizontal(this.backgroundImage.route));
  }

  fillVertical() {
    this.store.dispatch(new CanvasActions.FillVertical(this.backgroundImage.route));
  }

  rotate(rotation: number) {
    if (this.backgroundImage.rotation !== rotation) {
      this.store.dispatch(
        new FabricCanvasActions.ChangeElementRotation(
          this.backgroundImage.route,
          this.backgroundImage.screenWidth,
          this.backgroundImage.screenHeight,
          this.backgroundImage.screenX,
          this.backgroundImage.screenY,
          rotation
        )
      );
    }
  }

  flipHorizontal() {
    this.store.dispatch(
      new CanvasActions.FlipHorizontal(this.backgroundImage.route, !this.backgroundImage.flipHorizontal)
    );
  }

  flipVertical() {
    this.store.dispatch(new CanvasActions.FlipVertical(this.backgroundImage.route, !this.backgroundImage.flipVertical));
  }

  chooseBackgroundColor(color: Color) {
    this.chooseColor.emit(color);
  }

  chooseForegroundColor(color: Color) {
    this.store.dispatch(new CanvasActions.ChangeColor(this.backgroundImage.route, color));
  }

  resetForegroundColor() {
    this.store.dispatch(new CanvasActions.ResetImageColor(this.backgroundImage.route));
  }

  changeTransparency(transparency: number) {
    this.store.dispatch(new FabricCanvasActions.ChangeElementOpacity(this.backgroundImage.route, transparency));
  }

  translate(event: CanvasCoordinate) {
    const screenX = this.backgroundImage.x + event.x;
    const screenY = this.backgroundImage.y + event.y;

    this.store.dispatch(
      new Translate(
        this.backgroundImage.route,
        this.backgroundImage.screenWidth,
        this.backgroundImage.screenHeight,
        screenX,
        screenY
      )
    );
  }

  resize(event: CanvasDimensions) {
    const leftTop = getCoordinatesAfterResize(this.backgroundImage, event);

    this.store.dispatch(
      new CanvasActions.Resize(
        this.backgroundImage.route,
        event.width,
        event.height,
        leftTop.x,
        leftTop.y
      )
    );
  }

  toggleMoreOptions() {
    this.showMoreOptions = !this.showMoreOptions;
    this.showMoreOptionsChange.emit(this.showMoreOptions);
  }
}
