import { Action } from "@ngrx/store";
import { FabricCanvasActionTypes } from "./action.types";

export class ChangeElementRotation implements Action {
  readonly type = FabricCanvasActionTypes.ChangeElementRotation;

  constructor(
    public route: number[],
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public angle: number
  ) { }
}
