import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { fabric } from 'fabric';
import { ICanvasDimensions } from 'fabric/fabric-impl';
import { debounce } from 'lodash-es';
import { SVG_VIEWBOX_SCALE } from 'react-text-editor/models/text-editor.model';
import { Subject, takeUntil } from 'rxjs';
import { CanvasActions } from 'src/app/actions';
import { ImageLibraryType } from 'src/app/image-library/image-library';
import { CanvasElement, CoatingType, Design, EditorSelection, ElementType, Font, PageElement, View, defaultMaterialType } from 'src/app/models';
import { DesignSet } from 'src/app/models/design-set';
import { AppState } from 'src/app/reducers';
import { getAddImageAsPhotoFrame } from 'src/app/reducers/permissions.reducer';
import { ConfigService, GetTextService, ImageUploadService, TextService } from 'src/app/services';
import { ImageData, UPLOAD_URL } from 'src/app/services/image-upload.service';
import { ContextMenuService } from 'src/app/shared/context-menu/context-menu.service';
import { ErrorDialogComponent } from 'src/app/shared/dialogs';
import { NAVBAR_HEIGHT, TOOLBAR_HEIGHT_GT_SM } from 'src/app/shared/layout-constants';
import { fileExtensions } from 'src/app/utils/element.utils';
import { GradientData, gradients } from 'src/app/utils/gradient.utils';
import { PageOverlayObjectTypes, pageOverlayObjectData, pageOverlayObjectTypes } from 'src/app/utils/page-overlay.utils';
import { AlignGuidelines } from '../alignment-guidelines/aligning';
import * as FabricCanvasActions from './actions';
import { X_COAT_ROUTE, X_CROP_ROUTE, X_CUT_THROUGH_ROUTE, X_PAGE_OVERLAY_BLEEDING_ROUTE, X_PAGE_OVERLAY_CUTTING_ROUTE, X_PAGE_OVERLAY_SAFETY_ROUTE, X_PAGE_OVERLAY_SAFETY_TEXT_ROUTE, X_REPLACE_CONTROL_ROUTE, X_ROUTE, X_SPECIAL_COLOR_ROUTE, X_TEMP_BEFORE_TRANSFORM_INDEX, X_TEMP_EXCLUDE_FROM_CUT_THROUGH, X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR, X_TEMP_SETUP_OBJECT_BEFORE_MODIFYING, X_TEXT_IMAGE_ROUTE } from './fabric/constants/object-keys';
import { IMAGE_REPLACE_SVG } from './fabric/controls/image-replace.control';
import { CanvasIdService } from './services/canvas-id.service';
import { CanvasUiService } from './services/canvas-ui.service';
import * as CanvasElementUtils from './utils/canvas-element.utils';
import * as CanvasUtils from "./utils/canvas.utils";
import { FontUtils } from './utils/font.utils';
import * as GradientUtils from './utils/gradient.utils';
import { InlineTextElementUtils } from './utils/inline-text-element.utils';
import { calculatePosition, clearObjectTempKeys, onObjectTransformContainObject, onObjectTransformContainWithinObject, onObjectTransform_TransformObject, setTransformMatrix } from './utils/object-event.utils';
import { getAngle, getObjectOriginal, setPositionAfterZooming } from './utils/object.utils';
import { TextboxUtils } from './utils/textbox.utils';

@Component({
  selector: 'ed-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
  providers: [ImageUploadService]
})
export class CanvasComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('downLg', { static: false })
  downLg: ElementRef;

  @ViewChild('canvasContainer', { static: false })
  canvasContainer: ElementRef;

  @Input()
  isMainCanvas: boolean;

  @Input()
  isBehindMainCanvas: boolean;

  private canvasId: string;

  private canvas: fabric.Canvas;

  private _canvasDimensions: ICanvasDimensions;

  @Input()
  set canvasDimensions(canvasDimensions: ICanvasDimensions) {
    this._canvasDimensions = canvasDimensions;

    if (this.canvas) {
      this.canvas.setDimensions(canvasDimensions);

      this.zoomCanvasToFitAfterRenderPage = !this.isBehindMainCanvas;
      this.zoomCanvasToFit();
    }
  }

  get canvasDimensions(): ICanvasDimensions {
    return this._canvasDimensions
  }

  init: boolean;

  private renderAbortController: AbortController;

  zoomCanvasToFitAfterRenderPage: boolean;

  private addImageAsPhotoFrame: boolean;

  private fontLibrary: Font[] = [];

  protected readonly unsubscribe$ = new Subject<void>();

  cutThroughBehindPages: PageElement[] = [];

  private alignGuidelines: AlignGuidelines;

  private debounceObjectModified: any;

  @Input()
  designSet: DesignSet;

  @Input()
  design: Design;

  private _page: PageElement;

  @Input()
  set page(page: PageElement) {
    const designViewChanged = this.page?.parent?.view !== page?.parent?.view;
    const pageRouteChanged = this.page?.route?.toString() !== page?.route?.toString();

    this._page = page;
    this.cutThroughBehindPages = page.getCutThroughBehindPages();

    if (this.canvas) {
      if (designViewChanged || pageRouteChanged) {
        this.canvas.clear();
        this.canvas.requestRenderAll();

        this.zoomCanvasToFitAfterRenderPage = !this.isBehindMainCanvas;
      }

      this.debounceRenderPageAsync(page);
    }
  }

  get page(): PageElement {
    return this._page;
  }

  @Input()
  set zoom(zoom: number) {
    if (this.canvas) {
      this.zoomCanvasToCenter(zoom);
    }
  }

  get zoom(): number {
    return this.canvas?.getZoom();
  }

  @Output()
  zoomChange = new EventEmitter<number>();

  get zoomToFit() {
    let pageWidth = this.page.visiblePageBoundingBox.width,
      pageHeight = this.page.visiblePageBoundingBox.height,
      marginLeft = this.designSet.marginLeft,
      marginRight = this.designSet.marginTop;

    if (!this.isMainCanvas && !this.isBehindMainCanvas) {
      pageWidth = this.page.width;
      pageHeight = this.page.height;
      marginLeft = 0;
      marginRight = 0;
    }

    return Math.min(
      (this.canvas.getWidth() - marginLeft * 2) / pageWidth,
      (this.canvas.getHeight() - marginRight * 2) / pageHeight,
      this.designSet.pixelsPerMm * 1.5
    );
  }

  @Input()
  set viewportTransform(viewportTransform: number[]) {
    if (this.canvas) {
      this.setCanvasViewportTransform(viewportTransform, false, false);
    }
  }

  get viewportTransform() {
    return this.canvas?.viewportTransform;
  }

  @Output()
  viewportTransformChange = new EventEmitter<number[]>();

  private _pageRelativeWidth: number;
  get pageRelativeWidth(): number {
    return this._pageRelativeWidth;
  }

  private _pageRelativeHeight: number;
  get pageRelativeHeight(): number {
    return this._pageRelativeHeight;
  }

  private _pageRelativeLeft: number;
  get pageRelativeLeft(): number {
    return this._pageRelativeLeft;
  }

  private _pageRelativeTop: number;
  get pageRelativeTop(): number {
    return this._pageRelativeTop;
  }

  private _pageRelativeRight: number;
  get pageRelativeRight(): number {
    return this._pageRelativeRight;
  }

  private _pageRelativeBottom: number;
  get pageRelativeBottom(): number {
    return this._pageRelativeBottom;
  }

  private _pageRelativeCenterX: number;
  get pageRelativeCenterX(): number {
    return this._pageRelativeCenterX;
  }

  private _pageRelativeCenterY: number;
  get pageRelativeCenterY(): number {
    return this._pageRelativeCenterY;
  }

  private _pageInvertedRelativeRight: number;
  get pageInvertedRelativeRight(): number {
    return this._pageInvertedRelativeRight;
  }

  private _pageInvertedRelativeBottom: number;
  get pageInvertedRelativeBottom(): number {
    return this._pageInvertedRelativeBottom;
  }

  get gridCellSize(): number {
    return this.configService.gridSize;
  }

  get rainbowBackgroundUrl(): string {
    return this.configService.rainbowBackgroundUrl;
  }

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private contextMenuService: ContextMenuService,
    private imageUploadService: ImageUploadService,
    private getTextService: GetTextService,
    private dialog: MatDialog,
    @Inject(DOCUMENT)
    private document: Document,
    private configService: ConfigService,
    private canvasIdService: CanvasIdService,
    private canvasUiService: CanvasUiService,
    private textService: TextService,
  ) {
    this.setupPermissionSubscriptions();
    this.setupFontLibrarySubscriptions();
    this.setupFabricCanvasActionSubscriptions();
  }

  ngOnInit(): void {
    this.canvasId = this.canvasIdService.generate();
  }

  ngAfterViewInit(): void {
    // Create canvas element.
    const canvasElement = document.createElement('canvas');
    canvasElement.id = this.canvasId;

    // Append canvas element to canvas container.
    this.canvasContainer.nativeElement.append(canvasElement);

    // Init canvas.
    const canvas = (this.canvas = new fabric.Canvas(this.canvasId, {
      renderOnAddRemove: false,
      altActionKey: 'none',
      uniScaleKey: 'none',
      selection: false,
      preserveObjectStacking: true,
      stopContextMenu: true,
      fireRightClick: true,
    }));

    if (this.isMainCanvas) {
      // Prevent default context menu when right click on object
      const body = this.document.getElementsByTagName('body')?.[0];
      body?.addEventListener('contextmenu', (e: any) => e.srcElement.className.includes('context-menu-dialog') && e.preventDefault());

      // Setup key event listeners.
      document.addEventListener('keydown', (this.keydownListener = (ev) => {
        this.onKeyDownInputSpecialCharacter(ev);
        this.onKeyDownChangeTextboxStyle(ev);
        this.onKeyDownMoveObject(ev);
      }));

      document.addEventListener('keyup', (this.keyupListener = (ev) => {
        this.onKeyUpMoveObject(ev);
      }));

      this.setupCanvasAlignGuidelines(canvas);

      this.setupCanvasTextEvents(canvas);
      this.setupCanvasObjectEvents(canvas);
      this.setupCanvasObjectCustomEvents(canvas);

      this.setupCanvasSelection(canvas);

      if (this.downLg) {
        // Setup touch event listeners.
        const upperCanvasElement = CanvasUtils.getUpperCanvasElement(canvas);
        if (upperCanvasElement) {
          upperCanvasElement.addEventListener('touchstart', (this.touchstart = (e: TouchEvent) => {
            this.onTouchStartPinchPan(e);
          }));

          upperCanvasElement.addEventListener('touchmove', (this.touchmove = (e: TouchEvent) => {
            this.onTouchMovePinchPan(e);
          }));

          upperCanvasElement.addEventListener('touchend', (this.touchend = (e: TouchEvent) => {
            this.onTouchEndPinchPan(e);
          }));
        }

        this.setupOnCanvasTouchEventClick(canvas);
      } else {
        this.setupOnCanvasMouseEventClick(canvas);
        this.setupOnCanvasMouseEventZoom(canvas);
        this.setupOnCanvasMouseEventDrag(canvas);

        this.setupOnCanvasMouseEventRenderBorders(canvas);
        this.setupOnCanvasMouseEventRenderReplaceControl(canvas);
        this.setupOnCanvasMouseEventUpsertImage(canvas);
      }
    }

    // Init canvas.
    this.init = true;

    // Trigger canvas update.
    this.canvasDimensions = this.canvasDimensions;
    this.page = this.page;
  }

  ngOnDestroy(): void {
    this.canvasIdService.remove(this.canvasId);

    if (this.isMainCanvas) {
      document.removeEventListener('keydown', this.keydownListener);
      document.removeEventListener('keyup', this.keyupListener);

      if (this.downLg) {
        const upperCanvasElement = CanvasUtils.getUpperCanvasElement(this.canvas);
        if (upperCanvasElement) {
          upperCanvasElement.removeEventListener('touchstart', this.touchstart);
          upperCanvasElement.removeEventListener('touchmove', this.touchmove);
          upperCanvasElement.removeEventListener('touchend', this.touchend);
        }
      }
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //#region Setup subscriptions

  private setupPermissionSubscriptions() {
    this.store.pipe(
      select(getAddImageAsPhotoFrame),
      takeUntil(this.unsubscribe$),
    ).subscribe((addImageAsPhotoFrame) => {
      this.addImageAsPhotoFrame = addImageAsPhotoFrame;
    });
  }

  private setupFontLibrarySubscriptions() {
    this.store.pipe(
      select(s => s.fontlibrary.fontlibrary),
      takeUntil(this.unsubscribe$),
    ).subscribe((fontLibrary) => {
      this.fontLibrary = fontLibrary;

      if (this.page) {
        this.debounceRenderPageOnFontLoadAsync(this.page);
      }
    });
  }

  private setupFabricCanvasActionSubscriptions() {
    this.actions$.pipe(
      ofType(
        FabricCanvasActions.types.ChangeInlineTextFontFamily,
        FabricCanvasActions.types.ChangeInlineTextFontSize,
        FabricCanvasActions.types.ChangeInlineTextFontStyle,
        FabricCanvasActions.types.ChangeInlineTextFontWeight,
        FabricCanvasActions.types.ChangeInlineTextLineHeight,
        FabricCanvasActions.types.ChangeInlineTextTextAlign,
        FabricCanvasActions.types.ChangeInlineTextUnderline,
      ),
      takeUntil(this.unsubscribe$),
    ).subscribe((action: FabricCanvasActions.AllInlineText) => {
      const target = this.findObject(action.route);
      if (!target || !(target instanceof fabric.Textbox)) {
        return;
      }

      const element = this.findElement(action.route);
      if (!element || !element.isInlineText()) {
        return;
      }

      switch (action.type) {
        case FabricCanvasActions.types.ChangeInlineTextFontFamily: {
          this.removeSpecialColorObject(action.route);

          element.text[0].lines[0].textSpans[0].font = action.fontFamily;

          target.set({
            fontFamily: FontUtils.getFontFamily(target.data.fontLibrary, action.fontFamily),
          });
          break;
        }

        case FabricCanvasActions.types.ChangeInlineTextFontSize: {
          target.set({
            fontSize: action.fontSize,
          });
          break;
        }

        case FabricCanvasActions.types.ChangeInlineTextFontStyle: {
          target.set({
            fontStyle: action.fontStyle,
          });
          break;
        }

        case FabricCanvasActions.types.ChangeInlineTextFontWeight: {
          target.set({
            fontWeight: action.fontWeight,
          });
          break;
        }

        case FabricCanvasActions.types.ChangeInlineTextLineHeight: {
          target.set({
            lineHeight: action.lineHeight,
          });
          break;
        }

        case FabricCanvasActions.types.ChangeInlineTextTextAlign: {
          target.set({
            textAlign: action.textAlign,
          });
          break;
        }

        case FabricCanvasActions.types.ChangeInlineTextUnderline: {
          target.set({
            underline: action.underline,
          });
          break;
        }
      }

      const options = {
        target,
      };

      target.canvas?.requestRenderAll();
      target.canvas?.fire('text:changed', options);
      target.fire('text:changed', options);
    });

    this.actions$.pipe(
      ofType(
        FabricCanvasActions.types.ChangePhotoFrameZoom,
      ),
      takeUntil(this.unsubscribe$),
    ).subscribe((action: FabricCanvasActions.AllPhotoFrame) => {
      switch (action.type) {
        case FabricCanvasActions.types.ChangePhotoFrameZoom: {
          const target = this.findObject(action.route);
          if (!target) {
            return;
          }

          const element = this.findElement(action.route);
          if (!element || !element.isPhotoFrame()) {
            return;
          }

          const zoomLevel = Math.min(Math.max(action.zoomLevel, element.firstChild.minZoom), element.firstChild.maxZoom);
          const zoomScale = zoomLevel / element.firstChild.zoomLevel;

          if (zoomScale === 1) {
            return;
          }

          const options = {
            transform: {
              target,
              action: 'zoomInner',
              original: getObjectOriginal(target),
              zoomScale,
            },
            target,
            action: 'zoomInner',
          };

          target.canvas?.fire('before:transform', options);
          target.fire('before:transform', options);

          if (zoomScale > 1) {
            target.canvas?.requestRenderAll();
            target.canvas?.fire('object:zoomInnerPlus', options);
            target.fire('zoomInnerPlus', options);
          } else if (zoomScale < 1) {
            target.canvas?.requestRenderAll();
            target.canvas?.fire('object:zoomInnerMinus', options);
            target.fire('zoomInnerMinus', options);
          }
          break;
        }
      }
    });

    this.actions$.pipe(
      ofType(
        FabricCanvasActions.types.ChangeElementRotation,
        FabricCanvasActions.types.ChangeElementOpacity
      ),
      takeUntil(this.unsubscribe$),
    ).subscribe((action: FabricCanvasActions.AllElements) => {
      const element = this.findElement(action.route);
      if (!element) {
        return;
      }

      const object = this.findObject(element.route);
      if (!object) {
        return;
      }

      switch (action.type) {
        case FabricCanvasActions.types.ChangeElementRotation: {
          const resultObject = fabric.util.rotatePoint(
            new fabric.Point(object.left, object.top),
            object.getCenterPoint(),
            fabric.util.degreesToRadians(action.angle - object.angle)
          );

          const options = {
            transform: {
              target: object,
              action: 'rotate',
              original: getObjectOriginal(object),
            },
            target: object,
            action: 'rotate',
          };

          if (!this.debounceObjectModified) {
            object.canvas?.fire('before:transform', options);
          }

          object.set({
            left: resultObject.x,
            top: resultObject.y,
            angle: action.angle
          });

          object.canvas?.fire('object:rotating', options);

          this.debounceObjectModified ??= debounce(() => {
            object.canvas.fire('object:modified', options);
            this.debounceObjectModified = null;
          }, 100);

          this.debounceObjectModified();

          break;
        }
        case FabricCanvasActions.types.ChangeElementOpacity: {
          object.set({
            opacity: (100 - action.transparency) / 100
          });

          if (element.isInlineText()) {
            const options = {
              target: object,
            };

            object.canvas?.fire('text:changed', options);
          }
          else {
            this.store.dispatch(new CanvasActions.ChangeTransparency(element.route, action.transparency));
          }
          this.canvas.requestRenderAll();

          break;
        }
      }
    });
  }

  private setObjectDirty(object: fabric.Object) {
    if (!object || object.dirty) {
      return;
    }

    object.set({
      dirty: true,
    });

    for (const relatedObject of [
      object.clipPath,
      this.findCoatObject(object[X_ROUTE]),
      this.findCropObject(object[X_ROUTE]),
      this.findTextImageObject(object[X_ROUTE]),
      ...(object instanceof fabric.Group && object.getObjects() || []),
    ]) {
      this.setObjectDirty(relatedObject);
    }

    const element = this.findElement(object[X_ROUTE]);
    if (!element) {
      return;
    }

    for (const childElement of element.children) {
      const childObject = this.findObject(childElement.route);
      this.setObjectDirty(childObject);
    }
  }

  //#endregion

  //#region Setup (canvas)

  private setupCanvasAlignGuidelines(canvas: fabric.Canvas) {
    this.alignGuidelines = new AlignGuidelines({
      canvas,
      highlightObjectOptions: {
        strokeColor: '#8B3DFF',
      },
      aligningOptions: {
        strokeColor: '#D53AC5',
      },
      ignoreCanvasObjTypes: [
        { key: 'elementType', value: ElementType.image },
        { key: 'elementType', value: ElementType.background },
        { key: 'elementType', value: ElementType.backgroundImage },
        { key: 'elementType', value: ElementType.page }
      ],
      ignoreSelectedObjTypes: [
        { key: 'elementType', value: ElementType.image }
      ]
    });

    this.alignGuidelines.init();
  }

  private setupCanvasTextEvents(canvas: fabric.Canvas) {
    canvas.on('text:editing:entered', (e) => this.onCanvasTextEditingEntered(e));
    canvas.on('text:editing:exited', (e) => this.onCanvasTextEditingExited(e));
    canvas.on('text:changed', (e) => this.onCanvasTextChanged(e));
  }

  private setupCanvasObjectEvents(canvas: fabric.Canvas) {
    canvas.on('before:transform', (e) => {
      this.onObjectBeforeTransform(e);
    });

    canvas.on('object:moving', (e) => {
      const target = e.transform?.target;
      this.canvasUiService.isModifyingObject = !!target;

      // Show safety edge only on mouse drag
      if (e.e instanceof TouchEvent || e.e instanceof MouseEvent) {
        this.showPageOverlay();
      }
      this.onObjectBeforeModifying(e);
      this.onObjectModifying(e);
    });

    canvas.on('object:rotating', (e) => {
      const target = e.transform?.target;
      this.canvasUiService.isModifyingObject = !!target;

      this.onObjectBeforeModifying(e);
      this.onObjectModifying(e);
    });

    canvas.on('object:scaling', (e) => {
      const target = e.transform?.target;
      this.canvasUiService.isModifyingObject = !!target;

      this.onObjectBeforeModifying(e);
      this.onObjectModifying(e);
    });

    canvas.on('object:resizing', (e) => {
      const target = e.transform?.target;
      this.canvasUiService.isModifyingObject = !!target;

      this.onObjectBeforeModifying(e);
      this.onObjectModifying(e);
    });

    canvas.on('object:modified', (e) => {
      this.canvasUiService.isModifyingObject = false;

      this.onObjectBeforeModified(e);
      this.onObjectModified(e);

      // Add clippath to crop object
      this.displayRestrictedCropObject(e.transform?.target[X_ROUTE]);

      // Clear canvas objects temp keys.
      for (const object of canvas.getObjects()) {
        clearObjectTempKeys(object);
      }
    });

    canvas.on('object:removed', (e) => {
      this.onCanvasObjectRemoved(e);
    });
  }

  private setupCanvasObjectCustomEvents(canvas: fabric.Canvas) {
    canvas.on('object:zoomInnerMinus', (e: fabric.IEvent<MouseEvent>) => {
      this.onObjectBeforeModifying(e);
      this.onObjectZoomInner(e);
    });

    canvas.on('object:zoomInnerPlus', (e: fabric.IEvent<MouseEvent>) => {
      this.onObjectBeforeModifying(e);
      this.onObjectZoomInner(e);
    });

    canvas.on('object:before:imageReplace', (e: fabric.IEvent<MouseEvent>) => {
      this.onObjectBeforeImageReplace(e);
    });

    canvas.on('object:imageReplace', (e: fabric.IEvent<MouseEvent>) => {
      this.onObjectImageReplace(e);
    });

    canvas.on('object:remove', (e: fabric.IEvent<MouseEvent>) => {
      this.onObjectRemove(e);
    });
  }

  private setupCanvasSelection(canvas: fabric.Canvas) {
    canvas.on('selection:created', (event: fabric.IEvent<MouseEvent>) => {
      const selectedTarget = event.selected?.[0];
      if (selectedTarget) {
        this.onObjectSelected(Object.assign(event, { target: selectedTarget }));
      }
    });

    canvas.on('selection:updated', (event: fabric.IEvent<MouseEvent>) => {
      const deselectedTarget = event.deselected?.[0];
      if (deselectedTarget) {
        this.onObjectDeselected(Object.assign(event, { target: deselectedTarget }));
      }

      const selectedTarget = event.selected?.[0];
      if (selectedTarget) {
        const route: number[] = selectedTarget?.[X_ROUTE];
        if (!route) return;

        const element = this.findElement(route);
        if (!element || element.isBackground()) return;

        this.onObjectSelected(Object.assign(event, { target: selectedTarget }));
      }
    });

    canvas.on('selection:cleared', async (event: fabric.IEvent<MouseEvent>) => {
      const deselectedTarget = event.deselected?.[0];
      if (deselectedTarget) {
        this.onObjectDeselected(Object.assign(event, { target: deselectedTarget }));
      }
    });
  }

  private setupOnCanvasTouchEventClick(canvas: fabric.Canvas) {
    let touchDownTargetCorner: boolean;
    let touchDownTime: number;

    canvas.on('mouse:down:before', (event: fabric.IEvent<MouseEvent>) => {
      touchDownTime = event.e.timeStamp;

      const target = event.target;
      const route: number[] = target?.[X_ROUTE];
      if (!route) return;

      touchDownTargetCorner = !!target?._findTargetCorner(event.pointer);
    });

    canvas.on('mouse:up', (event: fabric.IEvent<MouseEvent>) => {
      const target = event.target;
      const route: number[] = target?.[X_ROUTE];
      if (!route) return;

      const touchDuration = event.e.timeStamp - touchDownTime;

      if (canvas.getActiveObject() === target) {
        const element = this.findElement(route);
        if (!element || !element.selected) return;

        switch (event.button) {
          case 1: {
            if (!touchDownTargetCorner && touchDuration < 150) {
              if (target instanceof fabric.Textbox && target.editable) {
                target.enterEditing(event.e);
              } else {
                canvas.discardActiveObject(event.e);
              }
            }
            break;
          }
        }
      } else {
        if (target.selectable && touchDuration < 150) {
          canvas.setActiveObject(target, event.e);
        }
      }

      touchDownTime = undefined;
      touchDownTargetCorner = undefined;
    });
  }

  private setupOnCanvasMouseEventClick(canvas: fabric.Canvas) {
    let mouseDownActiveObject: fabric.Object;
    let mouseDownTargetCorner: boolean;
    let mouseDownTime: number;

    canvas.on('mouse:down:before', (event: fabric.IEvent<MouseEvent>) => {
      mouseDownTime = event.e.timeStamp;

      const target = event.target;
      const route: number[] = target?.[X_ROUTE];
      if (!route) return;

      this.debounceTogglePageOverlay(true);

      mouseDownActiveObject = canvas.getActiveObject();
      mouseDownTargetCorner = !!target?._findTargetCorner(event.pointer);
    });

    canvas.on('mouse:up', (event: fabric.IEvent<MouseEvent>) => {
      this.debounceTogglePageOverlay(false);

      const target = event.target;
      const route: number[] = target?.[X_ROUTE];
      if (!route) return;

      const clickDuration = event.e.timeStamp - mouseDownTime;

      const element = this.findElement(route);
      if (!element) return;

      switch (event.button) {
        case 1: {
          if (!element.selected || element.isText() || element.isInlineText()) return;

          if (!mouseDownTargetCorner && target === mouseDownActiveObject && clickDuration < 150) {
            canvas.discardActiveObject(event.e);
          }
          break;
        }
        case 3: {
          if (target.selectable) {
            canvas.setActiveObject(target);
            this.contextMenuService.openContextMenu(event.pointer.x, event.pointer.y, element);
          }
          break;
        }
      }

      mouseDownTargetCorner = undefined;
      mouseDownTime = undefined;
      mouseDownActiveObject = undefined;
    });
  }

  private setupOnCanvasMouseEventZoom(canvas: fabric.Canvas) {
    canvas.on('mouse:wheel', (opt: fabric.IEvent<WheelEvent>) => {
      const delta = opt.e.deltaY;

      let zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;

      zoom = Math.max(zoom, this.zoomToFit * this.page.minZoom);
      zoom = Math.min(zoom, this.zoomToFit * this.page.maxZoom);

      this.zoomCanvasToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);

      opt.e?.preventDefault();
      opt.e?.stopPropagation();
    });
  }

  private setupOnCanvasMouseEventDrag(canvas: fabric.Canvas) {
    const touchPoints: fabric.Point[] = [];

    canvas.on('mouse:move', (event: fabric.IEvent<TouchEvent | MouseEvent>) => {
      if ('MouseEvent' in window && event.e instanceof MouseEvent) {
        const prevTouchPoints = touchPoints.slice(0);

        switch (event.e.buttons) {
          case 1: {
            touchPoints[0] = new fabric.Point(event.e.clientX, event.e.clientY);

            if (
              prevTouchPoints.length &&
              canvas.getZoom() > this.zoomToFit
            ) {
              const target = event.target;

              if (
                !target ||
                this.findElement(target[X_ROUTE])?.isBackgroundElement()
              ) {
                const vpt = canvas.viewportTransform?.slice(0);

                if (vpt) {
                  const { x, y } = touchPoints[0].subtract(prevTouchPoints[0]);
                  vpt[4] += x;
                  vpt[5] += y;

                  this.setCanvasViewportTransform(vpt, false, true);
                }
              }
            }
            break;
          }
        }
      }
    });

    canvas.on('mouse:up', (event: fabric.IEvent<TouchEvent | MouseEvent>) => {
      if ('MouseEvent' in window && event.e instanceof MouseEvent) {
        touchPoints.length = 0;
      }
    });
  }

  private setupOnCanvasMouseEventRenderBorders(canvas: fabric.Canvas) {
    const that = this;
    let prevDragoverTarget: fabric.Object;
    let showControlsAfterRenderObjects: fabric.Object[] = [];

    canvas.on('after:render', (e: fabric.IEvent<MouseEvent>) => {
      for (let obj of showControlsAfterRenderObjects) {
        renderBorders(obj);
      }
    });


    canvas.on('mouse:over', (e: fabric.IEvent<MouseEvent>) => {
      if (!e.target) return;

      showControlsAfterRenderObjects = [];
      showControlsAfterRenderObjects.push(e.target);
      renderBorders(e.target);
    });

    canvas.on('mouse:wheel', (e: fabric.IEvent<MouseEvent>) => {
      if (!e.target) return;

      showControlsAfterRenderObjects.push(e.target);
    });

    canvas.on('mouse:out', (e: fabric.IEvent<MouseEvent>) => {
      prevDragoverTarget = undefined;
      showControlsAfterRenderObjects = [];
      canvas.renderTop();

      const object = this.canvas.getActiveObject();
      if (object && object instanceof fabric.Textbox && object.isEditing) {
        object.renderCursorOrSelection();
      }
    });

    canvas.on('dragover', (e: fabric.IEvent<MouseEvent>) => {
      let target = e.target;
      if (!target || target !== prevDragoverTarget) {
        canvas.renderTop();
      }

      if (target) {
        let element = this.findElement(target[X_ROUTE]);
        if (element && element.isPhotoFrame() && target !== prevDragoverTarget) {
          renderBorders(e.target);
        }
      }

      prevDragoverTarget = target;
    });

    canvas.on('dragleave', (e: fabric.IEvent<MouseEvent>) => {
      canvas.renderTop();
    });

    function renderBorders(object: fabric.Object | undefined) {
      //If target object is a replace control object, replace it with actual object
      if (object?.[X_REPLACE_CONTROL_ROUTE]) {
        object = that.findObject(object[X_REPLACE_CONTROL_ROUTE]);
      }

      let route: number[] = object?.[X_ROUTE];
      if (!route) return;

      let element = that.findElement(route);
      if (!element || element.selected || element.isBackground() || element.isBackgroundChild) return;

      if (object && object.selectable) {
        // @ts-expect-error
        object._renderControls(canvas.contextTop, { hasControls: false });

        if (element.isBoxChild) {
          let parentObject = that.findObject(element.parent.route);
          // @ts-expect-error
          parentObject._renderControls(canvas.contextTop, { hasControls: false });
        }

        // @ts-expect-error
        canvas.contextTopDirty = true;
      }
    }
  }

  private setupOnCanvasMouseEventRenderReplaceControl(canvas: fabric.Canvas) {
    canvas.on('mouse:over', async (e) => {
      const replaceControlObject = await this.createReplaceControlObjectAsync(e.target);
      if (replaceControlObject) {
        canvas.requestRenderAll();
      }
    });

    canvas.on('mouse:out', (e) => {
      const target = e.target;
      // @ts-expect-error
      const nextTarget = e.nextTarget;

      if (
        !target || !target[X_ROUTE] ||
        !nextTarget || !nextTarget[X_REPLACE_CONTROL_ROUTE] ||
        !(nextTarget[X_REPLACE_CONTROL_ROUTE].toString() === target[X_ROUTE].toString())
      ) {
        const replaceControlObjects = this.removeReplaceControlObjects();
        if (replaceControlObjects.length) {
          canvas.requestRenderAll();
        }
      }
    });

    canvas.on('before:render', (e) => {
      const replaceControlObjects = canvas.getObjects()
        .filter((o) => o[X_REPLACE_CONTROL_ROUTE]);

      for (const replaceControlObject of replaceControlObjects) {
        replaceControlObject.data.calcPosition();
      }
    });
  }

  private setupOnCanvasMouseEventUpsertImage(canvas: fabric.Canvas) {
    canvas.on('drop', (e: fabric.IEvent<MouseEvent>) => {
      // @ts-expect-error
      let dataTransfer = e.e?.dataTransfer;
      let serializedData = dataTransfer?.getData('text');
      if (!serializedData) {
        return;
      }

      let data = JSON.parse(serializedData);
      let { sid, url, height, width, isFoilable, isSvg, libraryType, droppable } = data;

      if (droppable !== 'droppable') {
        return;
      }

      e.e.preventDefault();

      switch (libraryType) {
        // Upsert: background image
        case ImageLibraryType.background: {
          let backgroundElement = this.page.children.find((el) => el.isBackground());
          let hasBackgroundImageElement = backgroundElement?.children.some((el) => el.isBackgroundImage());

          if (hasBackgroundImageElement) {
            this.store.dispatch(new CanvasActions.ChangeBackgroundImage(width, height, sid, url));
          } else {
            this.store.dispatch(new CanvasActions.AddBackgroundImage(width, height, sid, url));
          }
          break;
        }

        // Upsert: photoFrame image, image
        case ImageLibraryType.image: {
          let hoverObject = canvas.findTarget(e.e, true);
          let hoverElement = this.findElement(hoverObject?.[X_ROUTE]);

          if (!hoverElement || !(hoverElement.isImage() || hoverElement.isPhotoFrame()) || hoverElement.parent?.isBackground()) {
            let x = this.pageRelativeLeft ? (e.e.clientX - this.pageRelativeLeft) / this.zoom : null;
            let y = this.pageRelativeTop ? (e.e.clientY - (this.pageRelativeTop + NAVBAR_HEIGHT + TOOLBAR_HEIGHT_GT_SM)) / this.zoom : null;

            if (this.addImageAsPhotoFrame) {
              this.store.dispatch(new CanvasActions.AddImageAsPhotoFrame(width, height, sid, url, x, y, isFoilable));
            } else {
              this.store.dispatch(new CanvasActions.AddImage(width, height, sid, url, x, y, isFoilable));
            }
          } else {
            if (!isSvg && !!hoverElement.foilType) {
              this.openFoilErrorDialog();
            } else {
              let canBeFoilable = isFoilable || hoverElement.permissions.isFoilable;
              this.store.dispatch(new CanvasActions.ReplaceImage(hoverElement.route, width, height, width, height, sid, url, canBeFoilable));
            }
          }
          break;
        }
      }
    });
  }

  //#endregion

  private findElement(route: number[]) {
    if (!route) return null;
    return ((this.page?.route?.toString() === route.toString() && this.page) || this.page?.getElement(route.slice(0, -1))) as CanvasElement;
  }

  private findObject(route: number[]) {
    if (!route) return null;
    return this.canvas?.getObjects()?.find(o => o[X_ROUTE]?.toString() === route.toString());
  }

  private findObjectIndex(object: fabric.Object) {
    if (!object) return -1;
    return this.canvas?.getObjects()?.indexOf(object) ?? -1;
  }

  //#region Render

  private debounceRenderPageAsync = debounce(this.renderPageAsync, 100);
  private debounceRenderPageOnFontLoadAsync = debounce(async (page: PageElement) => {
    fabric.util.clearFabricFontCache();
    await this.renderPageAsync(page);
  }, 200);

  private async renderPageAsync(page: PageElement) {
    if (this.canvasUiService.isModifyingObject) {
      return;
    }

    this.renderAbortController?.abort();
    this.renderAbortController = new AbortController();

    let abortSignal = this.renderAbortController.signal;

    this.removeElementsFromCanvas();
    if (abortSignal.aborted) return;

    let pageIndex = this.findObjectIndex(this.findObject(page.route));
    pageIndex === -1 && (pageIndex = 0);

    await this.addElementToCanvasAsync(page, abortSignal, pageIndex);
    if (abortSignal.aborted) return;

    await this.addPageCutThroughToCanvasAsync(page, abortSignal);
    if (abortSignal.aborted) return;

    await this.addPageSpecialColorToCanvasAsync(page, abortSignal);
    if (abortSignal.aborted) return;

    this.addPageViewSeparatorToCanvas(page);

    if (this.isMainCanvas) {
      await this.addPageInstantReplaceControlObjectsToCanvasAsync(page, abortSignal);
      this.addPageOverlayToCanvasAsync(page);
      this.addPageGridToCanvas(page);
      this.setPageSelectedElementToCanvas(page);
    }

    if (this.zoomCanvasToFitAfterRenderPage || !(this.isMainCanvas || this.isBehindMainCanvas)) {
      this.zoomCanvasToFitAfterRenderPage = false;
      this.zoomCanvasToFit();
    }

    this.canvas.requestRenderAll();
  }

  private setPageSelectedElementToCanvas(page: PageElement) {
    const element = page.selectedElement;
    const object = this.findObject(element?.route);

    if (object) {
      this.canvas.setActiveObject(object);
    } else {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        this.canvas.discardActiveObject();
      }
    }
  }

  private async addPageInstantReplaceControlObjectsToCanvasAsync(page: PageElement, abortSignal?: AbortSignal) {
    for (const element of page.children.filter((el) => !!el.permissions.hasInstantReplaceablePlaceholder)) {
      const object = this.findObject(element.route);
      await this.createReplaceControlObjectAsync(object, abortSignal);
    }
  }

  private async addElementToCanvasAsync(element: CanvasElement, abortSignal?: AbortSignal, index: number = 0) {
    const that = this;

    const existingObject = this.findObject(element.route);
    const object = await this.createObjectAsync(element, existingObject);

    if (!object || abortSignal?.aborted) return -1;

    object.data = { ...object.data, downLg: this.downLg };

    setObjectOptions();
    setObjectSelectionOptions();
    setObjectClipPath();

    // Add object to canvas.
    if (!existingObject) {
      this.canvas.insertAt(object, index++, false);
    } else {
      this.canvas.moveTo(object, index++);
    }

    // Create coat object
    const coatObject = this.createCoatObject(element, object, abortSignal);
    coatObject && index++;

    // Create crop object.
    const cropObject = await this.createCropObjectAsync(element, object, abortSignal);
    cropObject && index++;

    // Create text image object.
    const textImageObject = await this.createTextImageObjectAsync(element, object, abortSignal);
    textImageObject && index++;

    // Add child elements to canvas
    for (const childElement of element.children) {
      const nextIndex = await this.addElementToCanvasAsync(childElement, abortSignal, index);
      nextIndex !== -1 && (index = nextIndex);
    }

    return index;

    function setObjectOptions() {
      object.set({
        evented: (
          element.isClickable &&
          !(element.permissions.isUntargetable && !that.designSet.untargetableElementsTargetable) &&
          !element.isPhotoFrameChild
        ),
        // @ts-expect-error
        activeOn: that.downLg ? undefined : 'down',
        [X_ROUTE]: element.route,
      });

      if (element.isBackgroundElement()) {
        that.updateBackgroundElementShadow();
      }
    }

    function setObjectSelectionOptions() {
      object.set({
        cornerSize: 12,
        transparentCorners: false,
        borderColor: '#8B3DFF',
        borderScaleFactor: 1,
        borderOpacityWhenMoving: 1,
        cornerColor: '#FFF',
        cornerStrokeColor: '#0008',
        cornerStyle: 'circle',
      });

      if (object instanceof fabric.Textbox) {
        object.set({
          editingBorderColor: '#8B3DFF',
        });
      }
    }

    function setObjectClipPath() {
      if (element.isPage()) {
        return;
      }

      let clipPath: fabric.Object;

      if (
        !element.permissions.isVisibleOutsideCropArea &&
        !(element.selected && element.isInlineText()) &&
        !((element.isCutThrough || element.isCutThroughInverted) && element.parent.isPage())
      ) {
        const parentObject = that.findObject(element.parent.route);
        parentObject?.set({ absolutePositioned: true });

        clipPath = parentObject;
      }

      object.set({ clipPath });
    }
  }

  private async createObjectAsync(element: CanvasElement, object?: fabric.Object) {
    const data = {
      fontLibrary: this.fontLibrary,
    };

    return await element.createObjectAsync(object, data);
  }

  private updateBackgroundElementShadow() {
    if (!this.isMainCanvas && !this.isBehindMainCanvas) {
      return;
    }

    const element = this.page?.children.find((e) => e.isBackgroundElement());
    if (!element) {
      return;
    }

    const object = this.findObject(element.route);
    if (!object) {
      return;
    }

    const zoom = this.canvas.getZoom();

    object.set({
      shadow: new fabric.Shadow({
        color: '#8A8A8A',
        offsetX: 2 / zoom,
        offsetY: 2 / zoom,
        blur: 8 / zoom,
      }),
    });
  }

  //#endregion

  //#region Cut through

  private removeCutThroughObject(route: number[]) {
    const element = this.findElement(route);
    if (!element) return;

    const object = this.findObject(route);
    if (!object) return;

    if (!object[X_TEMP_EXCLUDE_FROM_CUT_THROUGH]) {
      object[X_TEMP_EXCLUDE_FROM_CUT_THROUGH] = true;

      if (element.isPhotoFrameChild) {
        object.opacity = element.opacity || 1e-323;
      }

      const pageRoute = route?.slice(route.length - 2);
      const pageObject = this.findObject(pageRoute);
      if (!pageObject) return;

      // Update (inverted) cut through
      const cutThroughObject = this.findCutThroughObject(route);

      let cutThroughClipPath = this.findCutThroughObject(route, 'clipPath');
      if (cutThroughClipPath instanceof fabric.Group) {
        if (cutThroughClipPath.getObjects().includes(cutThroughObject)) {
          cutThroughClipPath.remove(cutThroughObject);
        }

        if (!cutThroughClipPath.getObjects().length) {
          this.canvas.remove(cutThroughClipPath);
          cutThroughClipPath = undefined;
        }
      }

      let cutThroughClipPathInverted = this.findCutThroughObject(route, 'clipPathInverted');
      if (cutThroughClipPathInverted instanceof fabric.Group) {
        if (cutThroughClipPathInverted.getObjects().includes(cutThroughObject)) {
          cutThroughClipPathInverted.remove(cutThroughObject);
        }

        if (!cutThroughClipPathInverted.getObjects().length) {
          this.canvas.remove(cutThroughClipPathInverted);
          cutThroughClipPathInverted = undefined;
        }
      }

      // Update page clip path.
      pageObject.set({
        clipPath: cutThroughClipPath?.set({
          clipPath: cutThroughClipPathInverted,
        }) || cutThroughClipPathInverted,
      });

      this.setObjectDirty(pageObject);
      pageObject.canvas?.requestRenderAll();
    }
  }

  private findCutThroughObject(route: number[], type: 'object' | 'clipPath' | 'clipPathInverted' = 'object') {
    const that = this;

    switch (type) {
      case 'object':
        return findCutThroughObject();
      case 'clipPath':
        return findCutThroughClipPath();
      case 'clipPathInverted':
        return findCutThroughClipPathInverted();
      default:
        return null;
    }

    function findCutThroughClipPath() {
      const pageRoute = route?.slice(route.length - 2);
      const pageObject = that.findObject(pageRoute);

      let result: fabric.Group;

      if (pageObject && pageObject.clipPath instanceof fabric.Group) {
        if (!pageObject.clipPath.inverted) {
          result = pageObject.clipPath;
        }
      }

      return result;
    }

    function findCutThroughClipPathInverted() {
      const pageRoute = route?.slice(route.length - 2);
      const pageObject = that.findObject(pageRoute);

      let result: fabric.Group;

      if (pageObject && pageObject.clipPath instanceof fabric.Group) {
        if (pageObject.clipPath.inverted) {
          result = pageObject.clipPath;
        }

        if (pageObject.clipPath.clipPath instanceof fabric.Group) {
          if (pageObject.clipPath.clipPath.inverted) {
            result = pageObject.clipPath.clipPath;
          }
        }
      }

      return result;
    }

    function findCutThroughObject() {
      let object: fabric.Object;

      object ??= findObject(findCutThroughClipPath());
      object ??= findObject(findCutThroughClipPathInverted());

      return object;

      function findObject(cutThroughClipPath: fabric.Object) {
        if (!(cutThroughClipPath instanceof fabric.Group)) {
          return null;
        }

        return cutThroughClipPath
          .getObjects()
          .flatMap((o) => [o, o.clipPath])
          .filter((o) => o)
          .find((o) => o[X_CUT_THROUGH_ROUTE].toString() === route.toString());
      }
    }
  }

  private async addPageCutThroughToCanvasAsync(pageElement: PageElement, abortSignal: AbortSignal) {
    const that = this;

    const pageObject = this.findObject(pageElement?.route);
    if (!pageObject) return;

    // Create cut through
    const { cutThroughClipPath } = await createCutThroughAsync(false);
    if (abortSignal?.aborted) return;

    const { cutThroughClipPath: cutThroughClipPathInverted } = await createCutThroughAsync(true);
    if (abortSignal?.aborted) return;

    // Remove (existing) cut through from canvas
    const canvasObjects = this.canvas.getObjects();
    const canvasObjectsToRemove = canvasObjects.filter((object) => object[X_CUT_THROUGH_ROUTE]?.toString() === pageElement.route.toString());
    this.canvas.remove(...canvasObjectsToRemove);

    // Add cut through to canvas
    const pageClipPath = cutThroughClipPath?.set({
      clipPath: cutThroughClipPathInverted?.set({
        absolutePositioned: true,
      }),
    }) || cutThroughClipPathInverted?.set({
      absolutePositioned: true,
    });

    if (pageClipPath) {
      const pageIndex = this.findObjectIndex(pageObject);
      const pageClipPathIndex = pageIndex + (!pageObject.clipPath ? 0 : -1);

      this.setObjectDirty(pageClipPath);
      this.canvas.insertAt(pageClipPath, pageClipPathIndex, !!pageObject.clipPath);

      if (pageClipPath.clipPath) {
        this.setObjectDirty(pageClipPath.clipPath);
        this.canvas.insertAt(pageClipPath.clipPath, pageClipPathIndex + (!pageObject.clipPath ? 0 : -1), !!pageObject.clipPath);
      }
    }

    // Set cut through as page clip path
    pageObject.set({
      clipPath: pageClipPath?.set({
        absolutePositioned: true,
      }),
    });

    async function createCutThroughAsync(inverted: boolean) {
      let cutThroughClipPath: fabric.Object;

      const objects: fabric.Object[] = [
        ...await createObjectsAsync(pageElement.getCutThroughElements(!inverted), false),
        ...await createObjectsAsync(pageElement.backSidePage?.getCutThroughElements(!inverted) ?? [], true),
      ];

      if (objects.length) {
        cutThroughClipPath = that.findCutThroughObject(pageElement.route, !inverted ? 'clipPath' : 'clipPathInverted');
        cutThroughClipPath ??= new fabric.Group([], {
          opacity: 1e-323,
          inverted,
          evented: false,
          // @ts-expect-error
          [X_CUT_THROUGH_ROUTE]: pageElement.route,
        });

        if (cutThroughClipPath instanceof fabric.Group) {
          cutThroughClipPath.remove(...cutThroughClipPath.getObjects());

          for (const object of objects) {
            cutThroughClipPath.addWithUpdate(object);
          }
        }
      }

      return { cutThroughClipPath };
    }

    async function createObjectsAsync(elements: CanvasElement[], backSide: boolean) {
      const objects: fabric.Object[] = [];

      for (const element of elements) {
        const object = await createObjectAsync(element, backSide);
        if (!object) continue;

        if (element.children.length) {
          for (const childElement of element.children) {
            const childObject = await createObjectAsync(childElement, backSide);
            if (!childObject) continue;

            childObject.set({
              clipPath: object.set({
                opacity: 1e-323,
                absolutePositioned: true,
              }),
            });
            objects.push(childObject);
          }
        } else {
          objects.push(object);
        }
      }

      return objects;
    }

    async function createObjectAsync(element: CanvasElement, backSide: boolean) {
      let object: fabric.Object;

      const originalObject = that.findObject(element.route);
      if (originalObject) {
        if (!originalObject[X_TEMP_EXCLUDE_FROM_CUT_THROUGH]) {
          const existingObject = that.findCutThroughObject(element.route);
          if (existingObject) {
            object = await that.createObjectAsync(element, existingObject);
          } else {
            object = await new Promise((resolve: (object: fabric.Object) => void) => {
              originalObject.clone(resolve, ["elementType"]);
            });
          }
        }
      } else {
        object = await that.createObjectAsync(element);
      }

      if (object) {
        setObjectOptions(element, object);

        if (backSide) {
          setBackSideObjectOptions(element, object);
        }
      }

      return object;

      function setObjectOptions(element: CanvasElement, object: fabric.Object) {
        object.set({
          opacity: element.opacity || 1e-323,
          clipPath: undefined,
          // @ts-expect-error
          [X_CUT_THROUGH_ROUTE]: element.route,
        });
      }

      function setBackSideObjectOptions(element: CanvasElement, object: fabric.Object) {
        const angle = (360 - getAngle(element.screenRotation)) % 360;
        const { x: left, y: top } = fabric.util.rotatePoint(
          calculatePoint(),
          calculateOrigin(),
          fabric.util.degreesToRadians(angle)
        );

        object.set({
          left,
          top,
          angle,
          flipX: !object.flipX,
        });

        object.setCoords();

        function calculatePoint(): fabric.Point {
          const offsetLeft = calculateOffsetLeft();

          return new fabric.Point(
            -element.designX + element.pageX * 2 - element.width + element.pageWidth - offsetLeft,
            element.designY
          );
        }

        function calculateOrigin(): fabric.Point {
          const offsetLeft = calculateOffsetLeft();

          if (element.isBoxChild || element.isPhotoFrameChild) {
            return new fabric.Point(
              -element.parent.designX + element.pageX * 2 - element.parent.width * 0.5 + element.pageWidth - offsetLeft,
              element.parent.designY + element.parent.height * 0.5
            );
          }

          return new fabric.Point(
            -element.designX + element.pageX * 2 - element.width * 0.5 + element.pageWidth - offsetLeft * 2,
            element.designY + element.height * 0.5
          );
        }

        function calculateOffsetLeft() {
          let offsetLeft = 0;

          if (element.page.width !== element.page.backSidePage.width) {
            offsetLeft = Math.min(element.page.width, element.page.backSidePage.width) * 0.5;
          }

          return offsetLeft;
        }
      }
    }
  }

  //#endregion

  //#region Special color

  private removeSpecialColorObject(route: number[]) {
    const that = this;

    const element = this.findElement(route);
    if (!element) return;

    const object = this.findObject(route);
    if (!object) return;

    if (!object[X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR]) {
      object[X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR] = true;

      const pageRoute = route?.slice(route.length - 2);
      const pageObject = this.findObject(pageRoute);
      if (!pageObject) return;

      const specialColorClipPath = this.findSpecialColorObject(route, 'clipPath');
      removeObject(specialColorClipPath);

      function removeObject(clipPath: fabric.Object) {
        if (!(clipPath instanceof fabric.Group)) {
          return;
        }

        const objectToRemove = that.findSpecialColorObject(route);

        if (clipPath.getObjects().includes(objectToRemove)) {
          clipPath.remove(objectToRemove);

          const specialColorRect = that.findSpecialColorObject(route, 'rect');
          that.setObjectDirty(specialColorRect);

          const specialColorImage = that.findSpecialColorObject(route, 'image');
          that.setObjectDirty(specialColorImage);

          pageObject.canvas?.requestRenderAll();
        }
      }
    }
  }

  private findSpecialColorObject(route: number[], type: 'object' | 'rect' | 'image' | 'clipPath' = 'object') {
    const that = this;

    switch (type) {
      case 'object':
        return findObject();
      case 'rect':
        return findRect();
      case 'image':
        return findImage();
      case 'clipPath':
        return findClipPath();
      default:
        return null;
    }

    function findRect() {
      const pageRoute = route?.slice(route.length - 2);
      if (!pageRoute) {
        return null;
      }

      return that.canvas
        .getObjects()
        .find((o) => o[X_SPECIAL_COLOR_ROUTE]?.toString() === pageRoute.toString() && o instanceof fabric.Rect);
    }

    function findImage() {
      const pageRoute = route?.slice(route.length - 2);
      if (!pageRoute) {
        return null;
      }

      return that.canvas
        .getObjects()
        .find((o) => o[X_SPECIAL_COLOR_ROUTE]?.toString() === pageRoute.toString() && o instanceof fabric.Image);
    }

    function findClipPath() {
      const pageRoute = route?.slice(route.length - 2);
      if (!pageRoute) {
        return null;
      }

      return that.canvas
        .getObjects()
        .find((o) => o[X_SPECIAL_COLOR_ROUTE]?.toString() === pageRoute?.toString() && o instanceof fabric.Group);
    }

    function findObject() {
      const clipPath = findClipPath();
      if (!(clipPath instanceof fabric.Group)) {
        return null;
      }

      return clipPath
        .getObjects()
        .find((o) => o[X_SPECIAL_COLOR_ROUTE].toString() === route.toString());
    }
  }

  private async addPageSpecialColorToCanvasAsync(pageElement: PageElement, abortSignal: AbortSignal) {
    const that = this;

    const pageObject = this.findObject(pageElement.route);
    if (!pageObject) return;

    // Create special color
    const { clipPath, rect, image } = await createSpecialColorAsync();
    if (abortSignal?.aborted) return;

    // Remove (existing) special color from canvas
    const canvasObjects = this.canvas.getObjects();
    const canvasObjectsToRemove = canvasObjects.filter((object) => object[X_SPECIAL_COLOR_ROUTE]?.toString() === pageElement.route.toString());
    this.canvas.remove(...canvasObjectsToRemove);

    // Add special color to canvas
    if (clipPath) {
      this.setObjectDirty(clipPath);
      this.canvas.add(clipPath);

      if (rect) {
        this.setObjectDirty(rect);
        this.canvas.add(rect);
      }

      if (image) {
        this.setObjectDirty(image);
        this.canvas.add(image);
      }
    }

    async function createSpecialColorAsync() {
      let clipPath: fabric.Object;
      let rect: fabric.Object;
      let image: fabric.Object;

      const elements: CanvasElement[] = [];
      let gradient: GradientData;

      const foilElements = pageElement.getFoilElements();
      if (foilElements.length) {
        elements.push(...foilElements);

        const foilType = pageElement.getFoilType();
        gradient = gradients[foilType];
      } else {
        const spotUvElements = pageElement.getSpotUvElements();
        if (spotUvElements.length) {
          elements.push(...spotUvElements);
          gradient = gradients.spotUv;
        }
      }

      const objects: fabric.Object[] = [
        ...await createObjectsAsync(elements),
      ];

      if (objects.length && gradient) {
        clipPath = that.findSpecialColorObject(pageElement.route, 'clipPath');
        clipPath ??= new fabric.Group([], {
          opacity: 1e-323,
          evented: false,
          // @ts-expect-error
          [X_SPECIAL_COLOR_ROUTE]: pageElement.route,
        });

        clipPath.set({
          clipPath: pageObject.set({
            absolutePositioned: true,
          }),
        });

        if (clipPath instanceof fabric.Group) {
          clipPath.remove(...clipPath.getObjects());

          for (const object of objects) {
            clipPath.addWithUpdate(object);
          }
        }

        switch (gradient.id) {
          case gradients.rainbow.id: {
            const rainbowPatternURL = that.rainbowBackgroundUrl;

            image = that.findSpecialColorObject(pageElement.route, 'image');
            image ??= await new Promise((resolve: (image: fabric.Image) => void) => fabric.Image.fromURL(rainbowPatternURL, (image: fabric.Image) => {
              image.set({
                left: pageObject.left,
                top: pageObject.top,
                scaleX: pageObject.width / image.width,
                scaleY: pageObject.height / image.height,
                evented: false,
                // @ts-expect-error
                [X_SPECIAL_COLOR_ROUTE]: pageElement.route,
              });
              resolve(image);
            }, {
              crossOrigin: 'anonymous',
            }));

            image.set({
              clipPath: clipPath.set({
                absolutePositioned: true,
              }),
            });
            break;
          }

          default: {
            rect = that.findSpecialColorObject(pageElement.route, 'rect');
            rect ??= new fabric.Rect({
              left: pageObject.left,
              top: pageObject.top,
              width: pageObject.width,
              height: pageObject.height,
              evented: false,
              // @ts-expect-error
              [X_SPECIAL_COLOR_ROUTE]: pageElement.route,
            });

            rect.set({
              fill: GradientUtils.createGradient(rect.width * 1.25, rect.height * 1.25, gradient),
              clipPath: clipPath.set({
                absolutePositioned: true,
              }),
            });
          }
        }
      }

      return { clipPath, rect, image };
    }

    async function createObjectsAsync(elements: CanvasElement[]) {
      const objects: fabric.Object[] = [];

      for (const element of elements) {
        const object = await createObjectAsync(element);
        if (!object) continue;

        if (element.children.length) {
          for (const childElement of element.children) {
            const childObject = await createObjectAsync(childElement);
            if (!childObject) continue;

            childObject.set({
              clipPath: object.set({
                opacity: 1e-323,
                absolutePositioned: true,
              }),
            });
            objects.push(childObject);
          }
        } else {
          objects.push(object);
        }
      }

      return objects;
    }

    async function createObjectAsync(element: CanvasElement) {
      let object: fabric.Object;

      const originalObject = that.findObject(element.route);
      if (!originalObject || originalObject[X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR]) {
        return null;
      }

      const existingObject = that.findSpecialColorObject(element.route);

      if (existingObject) {
        if (element.isInlineText()) {
          const textImageObject = that.findTextImageObject(element.route);
          if (textImageObject) {
            object = await element.createImageAsync(existingObject);
          }
        } else {
          object = await that.createObjectAsync(element, existingObject);
        }
      } else {
        if (element.isInlineText()) {
          const textImageObject = that.findTextImageObject(element.route);
          if (textImageObject) {
            object = await new Promise((resolve: (object: fabric.Object) => void) => {
              textImageObject.clone(resolve, ["elementType"]);
            });
          }
        } else {
          object = await new Promise((resolve: (object: fabric.Object) => void) => {
            originalObject.clone(resolve, ["elementType"]);
          });
        }
      }

      if (object) {
        setObjectOptions(element, object);
      }

      return object;

      function setObjectOptions(element: CanvasElement, object: fabric.Object) {
        object.set({
          clipPath: undefined,
          // @ts-expect-error
          [X_SPECIAL_COLOR_ROUTE]: element.route,
        });
      }
    }
  }

  //#endregion

  //#region Page overlay

  private findPageOverlayObject(type: PageOverlayObjectTypes): fabric.Object | null {
    if (!this.page) return null;

    return this.canvas?.getObjects()?.find(o => o[pageOverlayObjectData[type]]?.toString() === this.page.route.toString()) || null;
  }

  private addPageOverlayToCanvasAsync(pageElement: PageElement) {
    const that = this;

    const dashLength = 9 / this.canvas.getZoom();
    const strokeWidth = Math.max(Math.min(1 / this.canvas.getZoom(), 2), 0.1);

    const pageObject = this.findObject(pageElement?.route);
    if (!pageObject) return;

    createPageOverlayBorder(pageOverlayObjectTypes.BLEED, pageElement.bleed, { strokeDashArray: [dashLength, dashLength], strokeWidth });
    createPageOverlayBorder(pageOverlayObjectTypes.SAFETY, -pageElement.safety, { strokeDashArray: [dashLength, dashLength], strokeWidth, opacity: 1e-323 });
    createPageOverlayBorder(pageOverlayObjectTypes.CUT, 0, { strokeWidth, opacity: 1e-323 });

    createPageOverlayText(pageOverlayObjectTypes.SAFETY, pageOverlayObjectTypes.SAFETY_TEXT, this.getTextService.text.design.pageOverlay.safetyMargin);

    function createPageOverlayBorder(type: PageOverlayObjectTypes, offset: number = 0, options: fabric.IObjectOptions = {}): null {
      let pageOverlayObject = that.findPageOverlayObject(type);
      if (!pageOverlayObject) {
        pageOverlayObject = new fabric.Rect();

        if (!(pageOverlayObject instanceof fabric.Rect)) {
          return null;
        }

        pageOverlayObject.set({
          ...options,
          evented: false,
          selectable: false,
          fill: "transparent",
          stroke: "#808080",
          [pageOverlayObjectData[type]]: that.page.route,
          //@ts-expect-error
          elementType: ElementType.page
        });

        that.canvas.insertAt(pageOverlayObject, that.canvas.size() - 1, false);
      } else {
        that.canvas.bringToFront(pageOverlayObject);
      }

      pageOverlayObject.set({
        left: pageElement.screenX - offset,
        top: pageElement.screenY - offset,
        width: pageElement.width + 2 * offset,
        height: pageElement.height + 2 * offset,
        scaleX: 1,
        scaleY: 1,
        //@ts-expect-error
        rtl: (pageElement.parent.hasRoundedCorners && pageElement.borderRadius.leftTop) || 0,
        rtr: (pageElement.parent.hasRoundedCorners && pageElement.borderRadius.rightTop) || 0,
        rbr: (pageElement.parent.hasRoundedCorners && pageElement.borderRadius.rightBottom) || 0,
        rbl: (pageElement.parent.hasRoundedCorners && pageElement.borderRadius.leftBottom) || 0,
        strokeWidth: options.strokeWidth,
        strokeDashArray: options.strokeDashArray,
      });

      pageOverlayObject.setCoords();
    }

    async function createPageOverlayText(borderType: PageOverlayObjectTypes, textType: PageOverlayObjectTypes, text: string) {
      const pageOverlayObject = that.findPageOverlayObject(borderType);
      if (!pageOverlayObject) {
        return;
      }

      const textboxOptions = {
        fontSize: 14 / that.canvas.getZoom()
      };

      const existingOverlayTextbox = that.findPageOverlayObject(textType) as fabric.Textbox;

      if (!existingOverlayTextbox) {
        const overlayTextbox = new fabric.Text("\n " + text + " \n", {
          ...textboxOptions,
          evented: false,
          selectable: false,
          left: pageOverlayObject.left,
          top: pageOverlayObject.top,
          backgroundColor: '#D3D3D3',
          opacity: 1e-323,
          fontFamily: "Arial",
          lineHeight: 0.2,
          textAlign: "center",
          [pageOverlayObjectData[textType]]: that.page.route,
          //@ts-expect-error
          elementType: ElementType.page,
        });

        that.canvas.add(overlayTextbox);
      } else {
        existingOverlayTextbox.set({
          ...textboxOptions
        });

        that.canvas.bringToFront(existingOverlayTextbox);
      }
    }
  }

  private showPageOverlay() {
    this.setPageOverlayObjectOpacity(pageOverlayObjectTypes.CUT, 1);
    this.setPageOverlayObjectOpacity(pageOverlayObjectTypes.SAFETY, 1);
    this.setPageOverlayObjectOpacity(pageOverlayObjectTypes.SAFETY_TEXT, 0.5);
  }

  private debounceTogglePageOverlay = debounce((show: boolean) => {
    if (show) {
      this.showPageOverlay();
    } else {
      this.hidePageOverlay();
    }

    this.canvas.requestRenderAll();
  }, 200);

  private hidePageOverlay() {
    this.setPageOverlayObjectOpacity(pageOverlayObjectTypes.CUT, 1e-323);
    this.setPageOverlayObjectOpacity(pageOverlayObjectTypes.SAFETY, 1e-323);
    this.setPageOverlayObjectOpacity(pageOverlayObjectTypes.SAFETY_TEXT, 1e-323);
  }

  private setPageOverlayObjectOpacity(type: PageOverlayObjectTypes, opacity: number) {
    const pageOverlayObject = this.findPageOverlayObject(type);
    if (!pageOverlayObject) {
      return;
    }

    pageOverlayObject.set({
      opacity
    });
  }

  //#endregion

  //#region Canvas events

  private onCanvasTextEditingEntered(e: fabric.IEvent<Event>) {
    this.canvas.preserveObjectStacking = false;

    const target = e.target;
    if (!target) {
      return;
    }

    const element = this.findElement(target[X_ROUTE]);
    if (!element) {
      return;
    }

    if (this.downLg) {
      if (e.target instanceof fabric.Textbox && e.target.hiddenTextarea) {
        window.scrollTo({
          behavior: "smooth",
          left: e.target.hiddenTextarea.offsetLeft,
          top: e.target.hiddenTextarea.offsetTop - this.canvas.getHeight() * 0.5,
        });
      }
    }

    if (element.isInlineText() && element.editFullRange) {
      target.hasControls = true;
      this.store.dispatch(new CanvasActions.SetInlineTextEditMode(element.route, false));
    }
  }

  private onCanvasTextEditingExited(e: fabric.IEvent<Event>) {
    this.canvas.preserveObjectStacking = true;

    const target = e?.target;
    if (!target) {
      return;
    }

    const element = this.findElement(target[X_ROUTE]);
    if (!element) {
      return;
    }

    if (element.isInlineText() && !element.editFullRange) {
      this.store.dispatch(new CanvasActions.SetInlineTextEditMode(element.route, true));
    }
  }

  private onCanvasTextChanged(e: fabric.IEvent<Event>) {
    const target = e.target;
    if (!target || !(target instanceof fabric.Textbox)) {
      return;
    }

    const element = this.findElement(target[X_ROUTE]);
    if (!element) {
      return;
    }

    // Set shrink to fit
    if (element.permissions.shrinkToFit && !target.text.match(/[\r\n]/)) {
      while (target._textLines.length > 1) {
        target.set({
          fontSize: target.fontSize - 1,
        });
      }

      this.canvas.requestRenderAll();
    }

    const specialColorObject = this.findSpecialColorObject(element.route);
    if (specialColorObject instanceof fabric.Textbox) {
      specialColorObject.set({
        text: target.text,
      });

      this.canvas.requestRenderAll();
    }

    if (element.isText()) {
      this.store.dispatch(new CanvasActions.ChangeFontsize(element.route, target.fontSize));
      this.store.dispatch(new CanvasActions.ChangeText(element.route, target.text));

      const { x, y, width, height } = this.getObjectTransform(e.target);
      this.store.dispatch(new CanvasActions.Translate(element.route, width, height, x, y, true));
    }

    if (element.isInlineText()) {
      const { x, y, width, height } = this.getObjectTransform(e.target);
      element.text = TextboxUtils.getInlineTextElementText(target);

      const textWidth = element.width * SVG_VIEWBOX_SCALE - 2 * element.padding;
      this.textService.setWordSpacing(element, textWidth, element.padding);

      this.store.dispatch(new CanvasActions.ChangeTextInline(element.route, element.text, width, height, x, y));
    }
  }

  private onCanvasObjectRemoved(event: fabric.IEvent<MouseEvent>) {
    let target = event.target;
    let route: number[] = target[X_ROUTE];
    if (!route) return;

    this.removeCoatObject(route);
    this.removeCropObject(route);
    this.removeTextImageObject(route);
    this.removeCutThroughObject(route);
    this.removeSpecialColorObject(route);
  }

  private onObjectBeforeImageReplace(e: fabric.IEvent<MouseEvent>) {
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = function (event: any) {
      let file = event.target.files[0];
      let fileExtension = file.name.split('.').slice(-1)[0].toLowerCase();

      let isValidFileExtension = fileExtensions[fileExtension];
      if (isValidFileExtension) {
        Object.assign(e, { file });
      }

      e.target.canvas.fire('object:imageReplace', e);
      e.target.fire('imageReplace', e);
    };
    input.click();
  }

  private onObjectImageReplace(event: fabric.IEvent<MouseEvent>) {
    let route: number[] = event.target[X_ROUTE];
    if (!route) return;

    // @ts-expect-error
    let file: File = event.file;
    if (file) {
      this.uploadImage(route, file);
    } else {
      this.uploadImageError();
    }
  }

  private uploadImage(route: number[], file: File) {
    this.imageUploadService.uploadUrl = UPLOAD_URL;
    this.imageUploadService.uploadImage(file, (imageData: ImageData) => {
      this.store.dispatch(new CanvasActions.ReplaceImage(
        route,
        imageData.width,
        imageData.height,
        imageData.width,
        imageData.height,
        imageData.sid,
        '',
        false
      ));
    });
  }

  private uploadImageError() {
    this.dialog.open(ErrorDialogComponent, {
      width: '250px',
      data: {
        title: this.getTextService.text.dialog.upload.error.title,
        message: this.getTextService.text.dialog.upload.error.text,
        button: this.getTextService.text.dialog.button.ok
      }
    });
  }

  //#endregion

  //#region Object events

  private async onObjectSelected(event: fabric.IEvent<MouseEvent>) {
    const target = event.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (element.isBackgroundImage()) {
      this.showCropObject(element.route);
    }

    if (element.isInlineText()) {
      target.set({
        opacity: element.text[0].lines[0].textSpans[0].opacity || 1e-323
      });

      this.removeTextImageObject(element.route);
      this.removeSpecialColorObject(element.route);
      target[X_TEMP_EXCLUDE_FROM_SPECIAL_COLOR] = false;

      const options = {
        target,
      };

      target.canvas?.requestRenderAll();
      target.canvas?.fire('text:changed', options);
      target.fire('text:changed', options);
    }

    this.removeReplaceControlObjects();

    this.canvas.requestRenderAll();

    this.store.dispatch(new CanvasActions.Select(route));
  }

  private async onObjectDeselected(event: fabric.IEvent<MouseEvent>) {
    const target = event.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    if (element.isBackgroundImage()) {
      this.hideCropObject(element.route);
    }

    if (element.isPhotoFrame()) {
      this.hideCropObject(element.firstChild.route);
    }

    this.store.dispatch(new CanvasActions.Deselect());
  }

  private onObjectBeforeTransform(event: fabric.IEvent<MouseEvent>) {
    const object = event.transform?.target;
    if (!object) {
      return;
    }

    const element = this.findElement(object[X_ROUTE]);
    if (!element) {
      return;
    }

    if (element.isBox()) {
      for (const childElement of element.children) {
        const childObject = this.findObject(childElement.route);
        setTransformMatrix(childObject, object);

        const childTextImageObject = this.findTextImageObject(childElement.route);
        setTransformMatrix(childTextImageObject, object);
      }
    }

    if (element.isPhotoFrame()) {
      const coatObject = this.findCoatObject(element.route);
      setTransformMatrix(coatObject, object);

      const childObject = this.findObject(element.firstChild.route);
      setTransformMatrix(childObject, object);

      const childCropObject = this.findCropObject(element.firstChild.route);
      setTransformMatrix(childCropObject, object);
    }

    if (element.isPhotoFrameChild) {
      const cropObject = this.findCropObject(element.route);
      setTransformMatrix(cropObject, object);
    }

    if (element.isBackgroundImage()) {
      const cropObject = this.findCropObject(element.route);
      setTransformMatrix(cropObject, object);
    }
  }

  private onObjectBeforeModifying(event: fabric.IEvent<MouseEvent>) {
    const that = this;

    const object = event.transform?.target;
    if (!object || object[X_TEMP_SETUP_OBJECT_BEFORE_MODIFYING]) {
      return;
    }

    object[X_TEMP_SETUP_OBJECT_BEFORE_MODIFYING] = true;

    const element = this.findElement(object[X_ROUTE]);
    if (!element) {
      return;
    }

    // Configure object before transform.
    removeClipPath(object, element);
    removeCutThrough(object, element);
    removeSpecialColor(object, element);
    bringToFront(object, element);
    configureCrop(object, element);

    this.canvas.requestRenderAll();

    function removeClipPath(object: fabric.Object, element: CanvasElement) {
      if (!object || !element || !element.parent || !element.parent.isPage()) {
        return;
      }

      object.set({
        clipPath: undefined,
      });

      const coatObject = that.findCoatObject(element.route);
      coatObject?.set({
        clipPath: undefined,
      });
    }

    function removeCutThrough(object: fabric.Object, element: CanvasElement) {
      if (!object || !element) {
        return;
      }

      that.removeCutThroughObject(element.route);

      for (const childElement of element.children) {
        const childObject = that.findObject(childElement.route);
        removeCutThrough(childObject, childElement);
      }
    }

    function removeSpecialColor(object: fabric.Object, element: CanvasElement) {
      if (!object || !element) {
        return;
      }

      that.removeSpecialColorObject(element.route);

      for (const childElement of element.children) {
        const childObject = that.findObject(childElement.route);
        removeSpecialColor(childObject, childElement);
      }
    }

    function bringToFront(object: fabric.Object, element: CanvasElement) {
      if (!object || !element) {
        return;
      }

      const objectIndex = that.findObjectIndex(object);
      if (objectIndex !== -1) {
        object[X_TEMP_BEFORE_TRANSFORM_INDEX] = objectIndex;

        const overlayIndex = that.findObjectIndex(that.findPageOverlayObject(pageOverlayObjectTypes.BLEED));
        if (overlayIndex !== -1) {
          object.moveTo(overlayIndex - 1);
        } else {
          object.bringToFront();
        }
      }

      for (const childElement of element.children) {
        const childObject = that.findObject(childElement.route);
        bringToFront(childObject, childElement);
      }
    }

    function configureCrop(object: fabric.Object, element: CanvasElement) {
      if (!object || !element) {
        return;
      }

      switch (event.transform.action) {
        case 'drag': {
          if (element.isPhotoFrameChild) {
            that.clearCropObjectClipPath(element.route);
            that.showCropObject(element.route);
          }
          if (element.isPhotoFrame()) {
            that.hideCropObject(element.firstChild.route);
          }
          break;
        }

        case 'scaleX':
        case 'scaleY': {
          if (element.isPhotoFrame()) {
            that.clearCropObjectClipPath(element.firstChild.route);
            that.showCropObject(element.firstChild.route);
          }
          break;
        }
        default:
          if (element.isPhotoFrameChild) {
            that.hideCropObject(element.route);
          }
          if (element.isPhotoFrame()) {
            that.hideCropObject(element.firstChild.route);
          }
      }
    }
  }

  private onObjectModifying(e: fabric.IEvent<MouseEvent>) {
    const that = this;

    const object = e.transform?.target;
    if (!object) {
      return;
    }

    const element = this.findElement(object[X_ROUTE]);
    if (!element) {
      return;
    }

    switch (e.transform.action) {
      case 'drag': {
        handleObjectMove(object, element);
        break;
      }

      case 'rotate': {
        handleObjectRotate(object, element);
        break;
      }

      case 'resizing': {
        break;
      }

      case 'scale': {
        handleObjectScale(object, element);
        break;
      }

      case 'scaleX':
      case 'scaleY': {
        handleObjectCrop(object, element);
        break;
      }
    }

    updateElementPositionAndDimension(object, element);
    setDirty(object, element);

    this.canvas.requestRenderAll();

    function handleObjectMove(object: fabric.Object, element: CanvasElement) {
      if (element.isBox()) {
        for (const childElement of element.children) {
          const childObject = that.findObject(childElement.route);
          onObjectTransform_TransformObject(e, childObject);

          const childTextImageObject = that.findTextImageObject(childElement.route);
          onObjectTransform_TransformObject(e, childTextImageObject);
        }
      }

      if (element.isPhotoFrame()) {
        const coatObject = that.findCoatObject(element.route);
        onObjectTransform_TransformObject(e, coatObject);

        const childObject = that.findObject(element.firstChild.route);
        onObjectTransform_TransformObject(e, childObject);

        const childCropObject = that.findCropObject(element.firstChild.route);
        onObjectTransform_TransformObject(e, childCropObject);
      }

      if (element.isPhotoFrameChild) {
        const parentObject = that.findObject(element.parent.route);
        onObjectTransformContainObject(e, parentObject);

        const cropObject = that.findCropObject(element.route);
        onObjectTransform_TransformObject(e, cropObject);
      }

      if (element.isBackgroundImage()) {
        const cropObject = that.findCropObject(element.route);
        onObjectTransform_TransformObject(e, cropObject);
      }
    }

    function handleObjectRotate(object: fabric.Object, element: CanvasElement) {
      if (element.isBox()) {
        for (const childElement of element.children) {
          const childObject = that.findObject(childElement.route);
          onObjectTransform_TransformObject(e, childObject);

          const childTextImageObject = that.findTextImageObject(childElement.route);
          onObjectTransform_TransformObject(e, childTextImageObject);
        }
      }

      if (element.isPhotoFrame()) {
        const coatObject = that.findCoatObject(element.route);
        onObjectTransform_TransformObject(e, coatObject);

        const childObject = that.findObject(element.firstChild.route);
        onObjectTransform_TransformObject(e, childObject);

        const childCropObject = that.findCropObject(element.firstChild.route);
        onObjectTransform_TransformObject(e, childCropObject);
      }

      if (element.isBackgroundImage()) {
        const cropObject = that.findCropObject(element.route);
        onObjectTransform_TransformObject(e, cropObject);
      }
    }

    function handleObjectCrop(object: fabric.Object, element: CanvasElement) {
      if (element.isPhotoFrame()) {
        const childObject = that.findObject(element.firstChild.route);
        onObjectTransformContainWithinObject(e, childObject);

        const coatObject = that.findCoatObject(element.route);
        onObjectTransform_TransformObject(e, coatObject);
      }
    }

    function handleObjectScale(object: fabric.Object, element: CanvasElement) {
      if (element.isPhotoFrame()) {
        const coatObject = that.findCoatObject(element.route);
        onObjectTransform_TransformObject(e, coatObject);

        const childObject = that.findObject(element.firstChild.route);
        onObjectTransform_TransformObject(e, childObject);

        const childCropObject = that.findCropObject(element.firstChild.route);
        onObjectTransform_TransformObject(e, childCropObject);
      }

      if (element.isBackgroundImage()) {
        const cropObject = that.findCropObject(element.route);
        onObjectTransform_TransformObject(e, cropObject);
      }
    }

    function setDirty(object: fabric.Object, element: CanvasElement) {
      that.setObjectDirty(object);

      const specialColorRect = that.findSpecialColorObject(element.route, 'rect');
      that.setObjectDirty(specialColorRect);

      const specialColorImage = that.findSpecialColorObject(element.route, 'image');
      that.setObjectDirty(specialColorImage);

      for (const childElement of element.children) {
        const childObject = that.findObject(childElement.route);
        setDirty(childObject, childElement);
      }
    }

    function updateElementPositionAndDimension(object: fabric.Object, element: CanvasElement) {
      const { x, y, width, height, rotation } = that.getObjectTransform(object);
      element.screenX = x;
      element.screenY = y;
      element.screenWidth = width;
      element.screenHeight = height;
      element.rotation = rotation;

      for (const childElement of element.children) {
        const childObject = that.findObject(childElement.route);
        updateElementPositionAndDimension(childObject, childElement);
      }
    }
  }

  private onObjectBeforeModified(e: fabric.IEvent<MouseEvent>) {
    const that = this;

    const object = e.transform?.target;
    if (!object) {
      return;
    }

    const element = this.findElement(object[X_ROUTE]);
    if (!element) {
      return;
    }

    that.hidePageOverlay();

    // Configure object after transform.
    addClipPath(object, element);
    sendToBack(object, element);
    hideCrop(object, element);
    hideCutThroughCanvasObject(object, element);
    setDirty(object, element);

    this.canvas.requestRenderAll();

    function addClipPath(object: fabric.Object, element: CanvasElement) {
      if (!object || (object instanceof fabric.Textbox && object.selected) || !element || !element.parent || !element.parent.isPage()) {
        return;
      }

      const parentObject = that.findObject(element.parent.route);
      object.set({
        clipPath: parentObject,
      });

      const coatObject = that.findCoatObject(element.route);
      coatObject?.set({
        clipPath: object.clipPath,
      });
    }

    function sendToBack(object: fabric.Object, element: CanvasElement) {
      if (!object || !element) {
        return;
      }

      const objectIndex = object[X_TEMP_BEFORE_TRANSFORM_INDEX];
      if (objectIndex != null && objectIndex !== -1) {
        object.moveTo(objectIndex);
      }

      for (const childElement of element.children) {
        const childObject = that.findObject(childElement.route);
        sendToBack(childObject, childElement);
      }
    }

    function hideCrop(object: fabric.Object, element: CanvasElement) {
      if (!object || !element) {
        return;
      }

      switch (e.transform.action) {
        case 'drag': {
          if (element.isPhotoFrameChild) {
            that.hideCropObject(element.route);
          }
          break;
        }

        case 'scaleX':
        case 'scaleY': {
          if (element.isPhotoFrame()) {
            that.hideCropObject(element.firstChild.route);
          }
          break;
        }
      }
    }

    function hideCutThroughCanvasObject(object: fabric.Object, element: CanvasElement) {
      if (!object || !element) {
        return;
      }

      switch (e.transform.action) {
        case 'drag':
        case 'scaleX':
        case 'scaleY': {
          if (element.isPhotoFrame() && (element.isCutThrough || element.isCutThroughInverted)) {
            object.set({
              opacity: 1e-325,
            });
          }
          break;
        }
      }
    }

    function setDirty(object: fabric.Object, element: CanvasElement) {
      if (!object || !element) {
        return;
      }

      that.setObjectDirty(object);

      const specialColorRect = that.findSpecialColorObject(element.route, 'rect');
      that.setObjectDirty(specialColorRect);

      const specialColorImage = that.findSpecialColorObject(element.route, 'image');
      that.setObjectDirty(specialColorImage);

      for (const childElement of element.children) {
        const childObject = that.findObject(childElement.route);
        setDirty(childObject, childElement);
      }
    }
  }

  private onObjectModified(e: fabric.IEvent<MouseEvent>) {
    const that = this;

    const object = e.transform?.target;
    if (!object) {
      return;
    }

    const element = this.findElement(object[X_ROUTE]);
    if (!element) {
      return;
    }

    switch (e.transform.action) {
      case 'drag': {
        handleObjectMove(object, element);
        break;
      }

      case 'rotate': {
        handleObjectRotate(object, element);
        break;
      }

      case 'resizing':
      case 'scale': {
        if (element.isBox()) {
          handleObjectCrop(object, element);
          break;
        }

        handleObjectScale(object, element);
        break;
      }

      case 'scaleX':
      case 'scaleY': {
        handleObjectCrop(object, element);
        break;
      }
    }

    function handleObjectMove(object: fabric.Object, element: CanvasElement) {
      const { x, y, width, height } = that.getObjectTransform(object);
      that.store.dispatch(new CanvasActions.Translate(element.route, width, height, x, y, true));
    }

    function handleObjectRotate(object: fabric.Object, element: CanvasElement) {
      const { x, y, width, height, rotation } = that.getObjectTransform(object);
      that.store.dispatch(new CanvasActions.Rotate(element.route, width, height, x, y, rotation));
    }

    function handleObjectCrop(object: fabric.Object, element: CanvasElement) {
      const { x, y, width, height } = that.getObjectTransform(object);
      that.store.dispatch(new CanvasActions.Crop(element.route, width, height, x, y));
    }

    function handleObjectScale(object: fabric.Object, element: CanvasElement) {
      if (element.isInlineText()) {
        if (object instanceof fabric.Textbox) {
          for (const p of element.text) {
            for (const l of p.lines) {
              for (const ts of l.textSpans) {
                ts.fontSize = Number.parseInt((ts.fontSize * object.scaleX).toFixed(0), 10);
              }
            }
          }

          object.set({
            fontSize: Number.parseInt((object.fontSize * object.scaleX).toFixed(0), 10),
            width: object.width * object.scaleX,
            height: object.height * object.scaleY,
            scaleX: 1,
            scaleY: 1,
          });
        }
      }

      const { x, y, width, height } = that.getObjectTransform(object);
      that.store.dispatch(new CanvasActions.Resize(element.route, width, height, x, y));

      if (element.isInlineText()) {
        if (object instanceof fabric.Textbox) {
          element.text = TextboxUtils.getInlineTextElementText(object);

          const textWidth = element.width * SVG_VIEWBOX_SCALE - 2 * element.padding;
          that.textService.setWordSpacing(element, textWidth, element.padding);

          that.store.dispatch(new CanvasActions.ResizeTextInline(element.route, element.text, width, height, x, y, new EditorSelection()));
        }
      }
    }
  }

  private getObjectTransform(object: fabric.Object) {
    const route: number[] = object?.[X_ROUTE];
    if (!route) return null;

    const element = this.findElement(route);
    if (!element) return null;

    const left = object.left;
    const top = object.top;

    const width = object.width * object.scaleX;
    const height = object.height * object.scaleY;

    if (element.isBoxChild || element.isPhotoFrameChild) {
      const parentElement = element.parent;
      const parentObject = this.findObject(parentElement.route);

      const rotation = CanvasElementUtils.getRotation((object.angle - parentObject.angle) % 360);
      const { x, y } = fabric.util.rotatePoint(
        new fabric.Point(left, top),
        new fabric.Point(
          parentElement.designX + (parentObject.width * parentObject.scaleX) / 2,
          parentElement.designY + (parentObject.height * parentObject.scaleY) / 2
        ),
        fabric.util.degreesToRadians(-parentObject.angle - ((object.angle - parentObject.angle) % 360))
      );

      let correctedWidth = Math.max(width, element.parent.width);
      let correctedHeight = Math.max(height, element.parent.height);

      return { route, x: x - element.parent.designX, y: y - element.parent.designY, width: correctedWidth, height: correctedHeight, rotation };
    } else {
      const rotation = CanvasElementUtils.getRotation(object.angle);
      const { x, y } = fabric.util.rotatePoint(
        new fabric.Point(left, top),
        new fabric.Point(left - (width) / 2, top - height / 2),
        fabric.util.degreesToRadians(object.angle)
      );
      return { route, x: x - element.pageX, y: y - element.pageY, width, height: height, rotation };
    }
  }

  private onObjectRemove(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    if (!route) return;

    this.store.dispatch(new CanvasActions.RemoveElement(route));
  }

  private onObjectZoomInner(event: fabric.IEvent<MouseEvent>) {
    const target = event.transform?.target;
    const route: number[] = target?.[X_ROUTE];
    const zoomScale = event.transform['zoomScale'] ?? 0;
    if (!route) return;

    const element = this.findElement(route);
    if (!element) return;

    target.setCoords();

    for (const childElement of element.children) {
      const childObject = this.findObject(childElement.route);
      if (childObject && childElement.isImage()) {
        const prevChildOptions = getOptions(childObject);
        setPositionAfterZooming(target, childObject, zoomScale, childElement.maxZoom / 100);

        if (childElement.isPhotoFrameChild) {
          const nextChildOptions = getOptions(childObject);

          this.findCropObject(childElement.route)?.set(nextChildOptions)?.setCoords();
          this.findCoatObject(childElement.route)?.set(nextChildOptions)?.setCoords();

          const specialColorObject = this.findSpecialColorObject(childElement.route);
          specialColorObject?.set({
            ...nextChildOptions,
            left: specialColorObject.left + (prevChildOptions.left - nextChildOptions.left),
            top: specialColorObject.top + (prevChildOptions.top - nextChildOptions.top),
          });
        }

        const options = {
          ...event,
          transform: {
            ...event.transform,
            target: childObject,
            action: 'scale',
          },
          target: childObject,
          action: 'scale',
        };

        childObject.canvas?.fire('object:modified', options);
        childObject.fire('modified', options);
      }
    }

    target.canvas?.fire('object:modified', { target: target });
    target.fire('modified', { target });

    this.setObjectDirty(this.findSpecialColorObject(route, 'rect'));
    this.setObjectDirty(this.findSpecialColorObject(route, 'image'));

    this.canvas.requestRenderAll();

    function getOptions(object: fabric.Object) {
      return {
        left: object.left,
        top: object.top,
        scaleX: object.scaleX,
        scaleY: object.scaleY,
      };
    }
  }

  //#endregion

  //#region Coat object

  private findCoatObject(route: number[]) {
    if (!this.canvas || !route) {
      return;
    }

    return this.canvas
      .getObjects()
      .find(o => o[X_COAT_ROUTE]?.toString() === route.toString());
  }

  private createCoatObject(element: CanvasElement, object: fabric.Object, abortSignal?: AbortSignal) {
    const that = this;

    if (!CanvasElementUtils.hasCoating(element)) {
      return;
    }

    const index = calcIndex(element.page);
    if (index === -1) {
      return;
    }

    // Create coat object.
    const existingCoatObject = this.findCoatObject(element.route);
    const coatObject = existingCoatObject ?? (() => {
      const result = new fabric.Rect({
        opacity: 1,
        fill: CoatingType,
        evented: false,
        // @ts-expect-error
        [X_COAT_ROUTE]: element.route,
      });

      return result;
    })();

    if (!coatObject || abortSignal?.aborted) {
      return;
    }

    // Update coat object.
    coatObject.set({
      left: object.left,
      top: object.top,
      width: object.width,
      height: object.height,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
      flipX: object.flipX,
      flipY: object.flipY,
      angle: object.angle,
      clipPath: object.clipPath,
    });

    // Add coat object to canvas.
    if (!existingCoatObject) {
      this.canvas.insertAt(coatObject, index, false);
    } else {
      this.canvas.moveTo(coatObject, index);
    }

    return coatObject;

    function calcIndex(page: PageElement) {
      const backgroundElement = page.children.find((e) => e.isBackgroundElement());
      let index = -1;

      if (backgroundElement) {
        const design = page.parent;
        const isDefaultMaterialType = design.material.type === defaultMaterialType;

        if (isDefaultMaterialType) {
          if (!backgroundElement.children.length) {
            const backgroundObject = that.findObject(backgroundElement.route);
            index = that.findObjectIndex(backgroundObject);
          }
        } else {
          if (backgroundElement.children.length) {
            const backgroundImageElement = backgroundElement.children.sort((a, b) => b.order - a.order)[0];
            const backgroundImageObject = that.findObject(backgroundImageElement.route);
            index = that.findObjectIndex(backgroundImageObject);
          }
        }
      }

      return (index + 1) || -1;
    }
  }

  private removeCoatObject(route: number[]) {
    const coatObject = this.findCoatObject(route);
    if (!coatObject) {
      return;
    }

    this.canvas.remove(coatObject);
  }

  //#endregion

  //#region Crop object

  private findCropObject(route: number[]) {
    if (!this.canvas || !route) {
      return;
    }

    return this.canvas
      .getObjects()
      .find(o => o[X_CROP_ROUTE]?.toString() === route.toString());
  }

  private async createCropObjectAsync(element: CanvasElement, object: fabric.Object, abortSignal?: AbortSignal) {
    if (
      !this.isMainCanvas ||
      !element.isBackgroundImage() &&
      !(element.isPhotoFrameChild && element.parent.selected)
    ) {
      return;
    }

    // Create crop object.
    const existingCropObject = this.findCropObject(element.route);
    const cropObject = existingCropObject ?? await new Promise((resolve: (result: fabric.Object) => void) => {
      object.clone((result: fabric.Object) => {
        result.set({
          opacity: 1e-323,
          evented: false,
          clipPath: undefined,
          //@ts-expect-error
          [X_CROP_ROUTE]: element.route,
        });

        resolve(result);
      }, ["elementType"]);
    });

    if (!cropObject || abortSignal?.aborted) {
      return;
    }

    // Update crop object.
    cropObject.set({
      left: object.left,
      top: object.top,
      width: object.width,
      height: object.height,
      scaleX: object.scaleX,
      scaleY: object.scaleY,
      flipX: object.flipX,
      flipY: object.flipY,
      angle: object.angle,
    });

    // Add crop object to canvas.
    const index = this.findObjectIndex(object);

    if (!existingCropObject) {
      this.canvas.insertAt(cropObject, index, false);
    } else {
      this.canvas.moveTo(cropObject, index);
    }

    this.displayRestrictedCropObject(element.route);

    return cropObject;
  }

  private showCropObject(route: number[]) {
    const cropObject = this.findCropObject(route);
    if (!cropObject) {
      return;
    }

    cropObject.set({
      opacity: 0.5,
    });
  }

  private hideCropObject(route: number[]) {
    const cropObject = this.findCropObject(route);
    if (!cropObject) {
      return;
    }

    cropObject.set({
      opacity: 1e-323,
    });
  }

  private clearCropObjectClipPath(route: number[]) {
    const cropObject = this.findCropObject(route);
    if (!cropObject) {
      return;
    }

    cropObject.set({
      clipPath: undefined,
    });
  }

  private setCropObjectClipPath(route: number[]) {
    const cropObject = this.findCropObject(route);
    if (!cropObject) {
      return;
    }

    const element = this.findElement(route);
    if (!element) {
      return;
    }

    const parentObject = this.findObject(element.parent.route);
    const parentRect = createFabricRect(parentObject);
    const pageOverlayObject = this.findPageOverlayObject(pageOverlayObjectTypes.BLEED);
    const overlayRect = createFabricRect(pageOverlayObject);

    cropObject.set({
      clipPath: parentRect.set({
        clipPath: overlayRect
      })
    });

    function createFabricRect(object: fabric.Object): fabric.Rect {
      if (!object) {
        return null;
      }

      return new fabric.Rect({
        left: object.left,
        top: object.top,
        width: object.width,
        height: object.height,
        angle: object.angle,
        scaleX: object.scaleX,
        scaleY: object.scaleY,
        absolutePositioned: true,
        //@ts-expect-error
        rtl: object.rtl ?? 0,
        //@ts-expect-error
        rtr: object.rtr ?? 0,
        //@ts-expect-error
        rbr: object.rbr ?? 0,
        //@ts-expect-error
        rbl: object.rbl ?? 0,
      });
    }
  }

  private displayRestrictedCropObject(route: number[]) {
    const element = this.findElement(route);
    if (!element) {
      return;
    }

    if (element.isPhotoFrame()) {
      this.showCropObject(element.firstChild.route);
      this.setCropObjectClipPath(element.firstChild.route);
    }

    if (element.isPhotoFrameChild) {
      this.showCropObject(element.route);
      this.setCropObjectClipPath(element.route);
    }

    if (element.isBackgroundImage() && element.selected) {
      this.showCropObject(element.route);
    }
  }

  private removeCropObject(route: number[]) {
    const cropObject = this.findCropObject(route);
    if (!cropObject) {
      return;
    }

    this.canvas.remove(cropObject);
  }

  //#endregion

  //#region Text Image object

  private findTextImageObject(route: number[]) {
    if (!this.canvas || !route) {
      return;
    }

    return this.canvas
      .getObjects()
      .find(o => o[X_TEXT_IMAGE_ROUTE]?.toString() === route.toString());
  }

  private async createTextImageObjectAsync(element: CanvasElement, object: fabric.Object, abortSignal?: AbortSignal) {
    if (!element.isInlineText() || element.selected) {
      return;
    }

    // Create text image object.
    const existingTextImageObject = this.findTextImageObject(element.route);
    const textImageObject = existingTextImageObject
      ? await element.createImageAsync(existingTextImageObject)
      : await (async () => {
        const result = await element.createImageAsync(existingTextImageObject);
        if (!result) {
          return;
        }

        result.set({
          evented: false,
          //@ts-expect-error
          [X_TEXT_IMAGE_ROUTE]: element.route
        });

        return result;
      })();

    if (!textImageObject || abortSignal?.aborted) {
      return;
    }

    // Update text image object.
    textImageObject.set({
      clipPath: object.clipPath,
    });

    // Add text image object to canvas.
    const index = this.findObjectIndex(object);

    if (!existingTextImageObject) {
      this.canvas.insertAt(textImageObject, index, false);
    } else {
      this.canvas.moveTo(textImageObject, index);
    }

    return textImageObject;
  }

  private removeTextImageObject(route: number[]) {
    const textImageObject = this.findTextImageObject(route);
    if (!textImageObject) {
      return;
    }

    this.canvas.remove(textImageObject);
  }

  //#endregion

  //#region Replace control object

  private findReplaceControlObject(route: number[]) {
    if (!this.canvas || !route) {
      return;
    }

    return this.canvas
      .getObjects()
      .find(o => o[X_REPLACE_CONTROL_ROUTE]?.toString() === route.toString());
  }

  private async createReplaceControlObjectAsync(object: fabric.Object, abortSignal?: AbortSignal) {
    if (
      !this.isMainCanvas ||
      !object
    ) {
      return;
    }

    const element = this.findElement(object[X_ROUTE]);
    if (
      !element || element.selected ||
      !element.isPhotoFrame() || !element.firstChild || element.firstChild.isVectorImage ||
      !element.permissions.isInstantReplaceable
    ) {
      return;
    }

    // Create replace control object object.
    const existingReplaceControlObject = this.findReplaceControlObject(element.route);
    const replaceControlObject = existingReplaceControlObject ?? await new Promise((resolve: (result: fabric.Object) => void) => {
      fabric.loadSVGFromString(IMAGE_REPLACE_SVG, (results) => {
        const result = new fabric.Group(results, {
          hasBorders: false,
          hasControls: false,
          hoverCursor: 'pointer',
          // @ts-expect-error
          activeOn: 'up',
          [X_REPLACE_CONTROL_ROUTE]: element.route,
        });

        result.set({
          data: {
            calcPosition: function correctPosition() {
              const objectCenterPoint = object.getCenterPoint();
              const size = 36 / (object.canvas?.getZoom() ?? 1);
              const nPos = calculatePosition(objectCenterPoint.x, objectCenterPoint.y, -object.angle, objectCenterPoint);
              const rPos = calculatePosition(nPos.x - size / 2, nPos.y - size / 2, object.angle, objectCenterPoint);

              result.set({
                left: rPos.x,
                top: rPos.y,
                scaleX: size / replaceControlObject.width,
                scaleY: size / replaceControlObject.height,
                angle: object.angle,
              });
            },
          },
        });

        result.on('selected', (e) => {
          const options = { target: object };

          // @ts-expect-error
          this.onObjectSelected(options);

          // @ts-expect-error
          this.onObjectBeforeImageReplace(options);
        });

        resolve(result);
      });
    });

    if (!replaceControlObject || abortSignal?.aborted) {
      return;
    }

    // Update replace control object object.
    replaceControlObject.data.calcPosition();

    // Add replace control object object to canvas.
    if (!existingReplaceControlObject) {
      const index = this.canvas.getObjects().length;
      this.canvas.insertAt(replaceControlObject, index, true);
    } else {
      const index = this.canvas.getObjects().length - 1;
      this.canvas.moveTo(replaceControlObject, index);
    }

    return replaceControlObject;
  }

  private removeReplaceControlObjects() {
    const replaceControlObjects = this.canvas
      .getObjects()
      .filter((o) => {
        const route = o[X_REPLACE_CONTROL_ROUTE];
        if (!route) return false;

        const element = this.findElement(route);
        return !element || !element.permissions.hasInstantReplaceablePlaceholder;
      });

    if (!replaceControlObjects.length) {
      return [];
    }

    this.canvas.remove(...replaceControlObjects);
    return replaceControlObjects;
  }

  //#endregion

  //#region Zoom

  private zoomCanvasToFit() {
    const zoom = this.zoomToFit;
    this.zoomCanvasToCenter(zoom);
  }

  private zoomCanvasToCenter(zoom: number) {
    const center = this.canvas.getCenter();
    this.zoomCanvasToPoint({ x: center.left, y: center.top }, zoom);
  }

  private zoomCanvasToPoint(point: fabric.IPoint, zoom: number) {
    this.canvas.zoomToPoint(point, zoom);
    this.zoomChange.emit(zoom);

    if (this.isMainCanvas) {
      this.addPageOverlayToCanvasAsync(this.page);
    }

    const vpt = this.canvas.viewportTransform?.slice(0);
    if (vpt) {
      this.setCanvasViewportTransform(vpt);
    }
  }

  private setCanvasViewportTransform(viewportTransform: number[], hasLowerBoundaries: boolean = true, hasHigherBoundaries: boolean = true) {
    const vpt = viewportTransform?.slice(0);

    if (vpt) {
      const zoom = vpt[0];

      if (zoom <= this.zoomToFit) {
        if (hasLowerBoundaries) {
          vpt[4] = this.canvas.getWidth() * 0.5 - (this.page.designX + this.page.visiblePageBoundingBox.x + this.page.visiblePageBoundingBox.width * 0.5) * zoom;
          vpt[5] = this.canvas.getHeight() * 0.5 - (this.page.designY + this.page.visiblePageBoundingBox.y + this.page.visiblePageBoundingBox.height * 0.5) * zoom;
        }
      } else {
        if (hasHigherBoundaries) {
          const vptMinTranslateX = this.canvas.getWidth() * 0.5 - this.page.designX * zoom;
          const vptMaxTranslateX = vptMinTranslateX - this.page.width * zoom;
          vpt[4] = Math.max(Math.min(vpt[4], vptMinTranslateX), vptMaxTranslateX);

          const vptMinTranslateY = this.canvas.getHeight() * 0.5 - this.page.designY * zoom;
          const vptMaxTranslateY = vptMinTranslateY - this.page.height * zoom;
          vpt[5] = Math.max(Math.min(vpt[5], vptMinTranslateY), vptMaxTranslateY);
        }
      }

      this.canvas.setViewportTransform(vpt);
      this.viewportTransformChange.emit(vpt);

      this.calcCanvasViewportBoundaries();
      this.updateBackgroundElementShadow();

      this.canvas.requestRenderAll();
    }
  }

  private calcCanvasViewportBoundaries() {
    if (this.canvas) {
      const zoom = this.canvas.getZoom();
      const vptBoundaries = this.canvas.calcViewportBoundaries();

      if (vptBoundaries) {
        const { tl, br } = vptBoundaries;

        this._pageRelativeWidth = this.page.width * zoom;
        this._pageRelativeHeight = this.page.height * zoom;

        this._pageRelativeLeft = (-tl.x + (this.designSet.width - this.page.width) * 0.5) * zoom;
        this._pageRelativeTop = (-tl.y + (this.designSet.height - this.page.height) * 0.5) * zoom;
        this._pageRelativeRight = (-tl.x + (this.designSet.width + this.page.width) * 0.5) * zoom;
        this._pageRelativeBottom = (-tl.y + (this.designSet.height + this.page.height) * 0.5) * zoom;

        this._pageRelativeCenterX = (-tl.x + this.designSet.width * 0.5) * zoom;
        this._pageRelativeCenterY = (-tl.y + this.designSet.height * 0.5) * zoom;

        this._pageInvertedRelativeRight = (br.x - (this.designSet.width + this.page.width) * 0.5) * zoom;
        this._pageInvertedRelativeBottom = (br.y - (this.designSet.height + this.page.height) * 0.5) * zoom;
      }
    }
  }

  //#endregion

  //#region Canvas container

  private openFoilErrorDialog() {
    this.dialog.open(ErrorDialogComponent, {
      width: '400px',
      data: {
        title: this.getTextService.text.dialog.dropFoilError.title,
        message: this.getTextService.text.dialog.dropFoilError.message,
        button: this.getTextService.text.dialog.button.ok
      }
    });
  }

  //#endregion

  private addPageGridToCanvas(page: PageElement) {
    if (!this.designSet.showGrid) {
      return;
    }

    const that = this;

    let gridLines: fabric.Line[] = [];
    let cellSize = Math.round(page.width / this.gridCellSize);

    gridLines.push(...getGridLines());
    gridLines.push(...getMedianLines());

    this.canvas.add(...gridLines);

    function getGridLines() {
      let gridLines: fabric.Line[] = [];
      let lineOptions: fabric.ILineOptions = {
        stroke: 'rgba(67, 191, 254, .8)',
        strokeWidth: 1 / that.zoomToFit,
        strokeUniform: true,
        evented: false,
      };

      let x = page.x;
      while (x <= page.x + page.width) {
        let horizontalLine = new fabric.Line([
          x, page.y,
          x, page.y + page.height
        ],
          lineOptions);
        //@ts-expect-error
        horizontalLine.elementType = ElementType.background;
        gridLines.push(horizontalLine);
        x += cellSize;
      }

      let y = page.y;
      while (y <= page.y + page.height) {
        let verticalLine = new fabric.Line([
          page.x, y,
          page.x + page.width, y
        ], lineOptions);
        //@ts-expect-error
        verticalLine.elementType = ElementType.background;
        gridLines.push(verticalLine);
        y += cellSize;
      }

      return gridLines;
    }

    function getMedianLines() {
      let medianLines: fabric.Line[] = [];
      let lineOptions: fabric.ILineOptions = {
        stroke: 'rgba(255, 28, 65, .8)',
        strokeWidth: 1 / that.zoomToFit,
        strokeUniform: true,
        evented: false,
      };

      let horizontalLine = new fabric.Line([
        page.x, page.y + page.height / 2,
        page.x + page.width, page.y + page.height / 2
      ], lineOptions);

      //@ts-expect-error
      horizontalLine.elementType = ElementType.background;

      medianLines.push(horizontalLine);

      let verticalLine = new fabric.Line([
        page.x + page.width / 2, page.y,
        page.x + page.width / 2, page.y + page.height
      ], lineOptions);

      //@ts-expect-error
      verticalLine.elementType = ElementType.background;

      medianLines.push(verticalLine);

      return medianLines;
    }
  }

  private addPageViewSeparatorToCanvas(page: PageElement) {
    let design = page?.parent;
    if (!design || design.view !== View.userSpreads && design.view !== View.card) {
      return;
    }

    let spread = design.spreads.find(spread => spread.spreadPage.route.toString() == page.route.toString());
    let spreadPages = spread?.pages;
    spreadPages = spreadPages.slice(0, spreadPages.length - 1);

    let separatorLines: fabric.Line[] = [];
    let width: number;

    for (let spreadPage of spreadPages) {
      width ??= spreadPage.width;

      let separatorLine = new fabric.Line([
        page.x + width, page.y,
        page.x + width, page.y + page.height
      ], {
        stroke: 'rgba(128, 128, 128, 0.5)',
        strokeWidth: 1 / this.zoomToFit,
        strokeUniform: true,
        evented: false
      });
      //@ts-expect-error
      separatorLine.elementType = ElementType.background;
      separatorLines.push(separatorLine);
      width += spreadPage.width;
    }

    this.canvas.add(...separatorLines);
  }

  private removeElementsFromCanvas() {
    const canvasObjects = this.canvas.getObjects();
    const canvasObjectsToRemove = canvasObjects
      .filter((object) => {
        const route: number[] =
          object[X_ROUTE] ||
          object[X_CROP_ROUTE] ||
          object[X_COAT_ROUTE] ||
          object[X_TEXT_IMAGE_ROUTE] ||
          object[X_CUT_THROUGH_ROUTE] || // Skip remove
          object[X_SPECIAL_COLOR_ROUTE] || // Skip remove
          object[X_PAGE_OVERLAY_BLEEDING_ROUTE] || // Skip remove
          object[X_PAGE_OVERLAY_CUTTING_ROUTE] || // Skip remove
          object[X_PAGE_OVERLAY_SAFETY_ROUTE] || // Skip remove
          object[X_PAGE_OVERLAY_SAFETY_TEXT_ROUTE]; // Skip remove

        const element = this.findElement(route);
        if (!element) return true;

        return (
          // Remove crop
          (
            object[X_CROP_ROUTE] &&
            (
              element.isPhotoFrameChild && !element.parent.selected ||
              element.isBackground() && !element.selected ||
              object instanceof fabric.Image && element.imgSource !== object.getSrc()
            )
          ) ||
          // Remove coat
          (
            object[X_COAT_ROUTE] &&
            !CanvasElementUtils.hasCoating(element)
          ) ||
          // Remove text image
          (
            object[X_TEXT_IMAGE_ROUTE] &&
            element.selected
          )
        );
      });

    this.canvas.remove(...canvasObjectsToRemove);
  }

  trackById(index: number, item: CanvasElement) {
    return `${index}-${item.id}`;
  }

  //#region Key event listeners

  private keydownListener: (e: KeyboardEvent) => void;
  private keyupListener: (e: KeyboardEvent) => void;

  private onKeyDownInputSpecialCharacter(e: KeyboardEvent) {
    const that = this;

    const object = this.canvas.getActiveObject();
    if (!object) {
      return;
    }

    const element = this.findElement(object[X_ROUTE]);
    if (!element) {
      return;
    }

    if (object instanceof fabric.Textbox && object.isEditing) {
      if (e.metaKey || e.altKey) {
        switch (e.key.toLowerCase()) {
          case "e":
            addSpecialCharacter(object, "€")
            break;
          case "l":
            addSpecialCharacter(object, "@")
            break;
        }

        function addSpecialCharacter(target: fabric.Textbox, character: string) {
          target.insertChars(character, null, target.selectionStart, target.selectionEnd);

          target.selectionStart = target.selectionStart + character.length;
          target.selectionEnd = target.selectionStart;
          target.hiddenTextarea.value = target.text;
          target.hiddenTextarea.selectionStart = target.selectionStart;
          target.hiddenTextarea.selectionEnd = target.selectionStart;

          const options = {
            target,
          };

          target.canvas?.requestRenderAll();
          target.canvas?.fire('text:changed', options);
          target.fire('text:changed', options);
        }
      }
    }
  }

  private onKeyDownChangeTextboxStyle(e: KeyboardEvent) {
    if (!e.ctrlKey && !e.metaKey) {
      return;
    }

    const object = this.canvas.getActiveObject();
    if (!object || !(object instanceof fabric.Textbox)) {
      return;
    }

    const element = this.findElement(object[X_ROUTE]);
    if (!element) {
      return;
    }

    switch (e.key.toLowerCase()) {
      case 'b': {
        e.preventDefault();

        const fontWeight = InlineTextElementUtils.getTextboxFontWeight(!TextboxUtils.getInlineTextElementBold(object));
        this.store.dispatch(new FabricCanvasActions.ChangeInlineTextFontWeight(element.route, fontWeight));
        break;
      }

      case 'u': {
        e.preventDefault();

        const underline = !object.underline
        this.store.dispatch(new FabricCanvasActions.ChangeInlineTextUnderline(element.route, underline));
        break;
      }

      case 'i': {
        e.preventDefault();

        const fontStyle = InlineTextElementUtils.getTextboxFontStyle(!TextboxUtils.getInlineTextElementItalic(object));
        this.store.dispatch(new FabricCanvasActions.ChangeInlineTextFontStyle(element.route, fontStyle));
        break;
      }
    }
  }

  private debounceClearAlignGuidelines = debounce(() => {
    this.alignGuidelines.clearLines();
    this.canvas.requestRenderAll();
  }, 150);

  private onKeyDownMoveObject(e: KeyboardEvent) {
    const that = this;

    if (e.ctrlKey || e.metaKey || e.altKey) {
      return;
    }

    const object = this.canvas.getActiveObject();
    if (!object) {
      return;
    }

    const element = this.findElement(object[X_ROUTE]);
    if (!element) {
      return;
    }

    const offset = e.shiftKey ? 10 : 1;

    switch (e.key) {
      case 'ArrowLeft': {
        moveObjectBy('left', -offset);
        break;
      }

      case 'ArrowUp': {
        moveObjectBy('top', -offset);
        break;
      }

      case 'ArrowRight': {
        moveObjectBy('left', offset);
        break;
      }

      case 'ArrowDown': {
        moveObjectBy('top', offset);
        break;
      }
    }

    function moveObjectBy(axis: 'left' | 'top', offset: number) {
      // Disable align guidelines magnetism.
      that.alignGuidelines.disableMagnetism();

      const options = {
        e,
        transform: {
          target: object,
          action: 'drag',
          original: getObjectOriginal(object),
        },
        target: object,
        action: 'drag',
      };

      // Handle object before transform.
      object.canvas?.fire('before:transform', options);
      object.fire('before:transform', options);

      // Handle object moving.
      object.set({ [axis]: object[axis] + offset });
      object.setCoords();

      object.canvas?.fire('object:moving', options);
      object.fire('moving', options);

      that.debounceClearAlignGuidelines();
      that.canvas.requestRenderAll();
    }
  }

  private onKeyUpMoveObject(e: KeyboardEvent) {
    const object = this.canvas.getActiveObject();
    if (!object) {
      return;
    }

    switch (e.key) {
      case 'ArrowLeft':
      case 'ArrowUp':
      case 'ArrowRight':
      case 'ArrowDown': {
        // Enable align guidelines magnetism.
        this.alignGuidelines.enableMagnetism();

        const options = {
          e,
          transform: {
            target: object,
            action: 'drag',
            original: getObjectOriginal(object),
          },
          target: object,
          action: 'drag',
        };

        // Handle object modified.
        object.canvas?.fire('object:modified', options);
        object.fire('modified', options);

        this.canvas.requestRenderAll();
        break;
      }
    }
  }

  //#endregion

  //#region Touch event listeners

  private touchstart: (e: TouchEvent) => void;
  private touchmove: (e: TouchEvent) => void;
  private touchend: (e: TouchEvent) => void;

  private touchPinPanPoints: fabric.Point[] = [];
  private touchPinPanPointsOffset: fabric.Point;
  private touchPinPanPointsTotalLength: number = 0;
  private touchPinPanActiveObject: fabric.Object;
  private touchPinPanActiveObjectOptions: Partial<fabric.IObjectOptions>;

  private onTouchStartPinchPan(e: TouchEvent) {
    if (!this.canTouchPinchPan()) {
      return;
    }

    switch (e.touches.length) {
      case 1:
      case 2: {
        const activeObject = this.canvas.getActiveObject();
        if (activeObject) {
          // Temp store active object.
          this.touchPinPanActiveObject = activeObject;

          // Temp store active object options.
          this.touchPinPanActiveObjectOptions = {
            lockMovementX: this.touchPinPanActiveObject.lockMovementX,
            lockMovementY: this.touchPinPanActiveObject.lockMovementY,
          };
        }
        break;
      }
    }

    this.touchPinPanPointsTotalLength = Math.max(this.touchPinPanPointsTotalLength, e.touches.length);
  }

  private onTouchMovePinchPan(e: TouchEvent) {
    if (!this.canTouchPinchPan()) {
      return;
    }

    const prevTouchPinPanPoints: fabric.Point[] = this.touchPinPanPoints.slice(0);

    switch (e.touches.length) {
      case 1: {
        this.touchPinPanPoints[0] = new fabric.Point(e.touches[0].clientX, e.touches[0].clientY);

        if (
          prevTouchPinPanPoints.length &&
          (this.canvas.getZoom() > this.zoomToFit || this.touchPinPanPointsTotalLength === 2)
        ) {
          const target = this.canvas.findTarget(e, false);

          if (
            !target ||
            target !== this.canvas.getActiveObject() ||
            this.findElement(target[X_ROUTE])?.isBackgroundElement()
          ) {
            const vpt = this.canvas.viewportTransform?.slice(0);

            if (vpt) {
              // Set viewport transform offset.
              if (this.touchPinPanPointsOffset) {
                vpt[4] += this.touchPinPanPointsOffset.x;
                vpt[5] += this.touchPinPanPointsOffset.y;
                this.touchPinPanPointsOffset = null;
              }

              // Calculate pan offset.
              const panOffset = this.touchPinPanPoints[0].subtract(prevTouchPinPanPoints[0]);
              vpt[4] += panOffset.x;
              vpt[5] += panOffset.y;

              this.setCanvasViewportTransform(vpt, false, true);
            }
          }
        }
        break;
      }

      case 2: {
        this.touchPinPanPoints[0] = new fabric.Point(e.touches[0].clientX, e.touches[0].clientY);
        this.touchPinPanPoints[1] = new fabric.Point(e.touches[1].clientX, e.touches[1].clientY);

        if (
          prevTouchPinPanPoints.length
        ) {
          if (this.touchPinPanActiveObject) {
            // Disable active object movement.
            this.touchPinPanActiveObject.set({
              lockMovementX: true,
              lockMovementY: true,
            });
          }

          const vpt = this.canvas.viewportTransform?.slice(0);

          if (vpt) {
            // Calculate zoom.
            const zoomPointBefore = this.touchPinPanPoints[0].midPointFrom(this.touchPinPanPoints[1]);
            const zoomDistance = prevTouchPinPanPoints[0].distanceFrom(prevTouchPinPanPoints[1]) - this.touchPinPanPoints[0].distanceFrom(this.touchPinPanPoints[1]);

            const delta = zoomDistance / (this.zoomToFit * this.page.minZoom * 2);

            let zoom = this.canvas.getZoom();
            zoom *= 0.999 ** delta;

            zoom = Math.max(zoom, this.zoomToFit * this.page.minZoom);
            zoom = Math.min(zoom, this.zoomToFit * this.page.maxZoom);

            vpt[0] = vpt[3] = zoom;

            // Calculate pinch offset.
            const zoomPointAfter = fabric.util.transformPoint(fabric.util.transformPoint(zoomPointBefore, fabric.util.invertTransform(this.canvas.viewportTransform)), vpt);
            const pinchOffset = zoomPointBefore.subtract(zoomPointAfter);

            // Calculate pan offset.
            const prevMidPoint = prevTouchPinPanPoints[0].midPointFrom(prevTouchPinPanPoints[1]);
            const nextMidPoint = this.touchPinPanPoints[0].midPointFrom(this.touchPinPanPoints[1]);
            const panOffset = nextMidPoint.subtract(prevMidPoint);

            vpt[4] += pinchOffset.x + panOffset.x;
            vpt[5] += pinchOffset.y + panOffset.y;

            this.setCanvasViewportTransform(vpt, false, false);
          }
        }
        break;
      }
    }
  }

  private onTouchEndPinchPan(e: TouchEvent) {
    if (!this.canTouchPinchPan()) {
      return;
    }

    switch (e.touches.length) {
      case 0: {
        // Restore active object options.
        if (this.touchPinPanActiveObject) {
          this.touchPinPanActiveObject.set(this.touchPinPanActiveObjectOptions);
        }

        // Clear temp values.
        this.touchPinPanActiveObject = null;
        this.touchPinPanActiveObjectOptions = null;
        this.touchPinPanPoints.length = 0;
        this.touchPinPanPointsTotalLength = 0;

        // Calculate viewport transform.
        const vpt = this.canvas.viewportTransform?.slice(0);

        if (vpt) {
          // Calculate zoom.
          const zoom = Math.max(this.canvas.getZoom(), this.zoomToFit);
          vpt[0] = vpt[3] = zoom;

          this.setCanvasViewportTransform(vpt);
        }
        break;
      }

      case 1: {
        // Calculate viewport offset.
        if (this.touchPinPanPoints.length === 2) {
          const currentPoint = new fabric.Point(e.touches[0].clientX, e.touches[0].clientY);
          const offsetPoint = currentPoint.eq(this.touchPinPanPoints[0])
            ? this.touchPinPanPoints[0]
            : this.touchPinPanPoints[1];

          this.touchPinPanPointsOffset = this.touchPinPanPoints[0].subtract(offsetPoint);
          this.touchPinPanPoints.length = 1;
        }
        break;
      }
    }
  }

  private canTouchPinchPan() {
    if (this.canvasUiService.isModifyingObject) {
      return false;
    }

    const activeObject = this.canvas.getActiveObject();
    if (activeObject instanceof fabric.Textbox) {
      if (activeObject.isEditing) {
        return false;
      }
    }

    return true;
  }

  //#endregion
}
