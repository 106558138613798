import { fabric } from "fabric";
import { BaseElement, CanvasElement, FoilTypes, PageElement } from "src/app/models";
import { ControlsVisibility } from "../controls-visibility";

declare module "src/app/models/page-element" {
  interface PageElement {
    getCutThroughBehindPages(): PageElement[];
    getCutThroughElements(inverted: boolean): CanvasElement[];
    getFoilElements(): CanvasElement[];
    getFoilType(): FoilTypes;
    getSpotUvElements(): CanvasElement[];
  }
}

PageElement.prototype.createObjectAsync = async function (object?: fabric.Object, data?: any): Promise<fabric.Object> {
  const element: PageElement = this;

  if (!(element instanceof PageElement)) {
    return null;
  }

  object ??= new fabric.Rect();

  if (!(object instanceof fabric.Rect)) {
    return null;
  }

  const objectOptions = element.getObjectOptions(object);
  object.set(objectOptions);

  const controlsVisibility = element.getObjectControlsVisibility(object);
  object.setControlsVisibility(controlsVisibility);

  return object;
}

PageElement.prototype.getObjectOptions = function (object: fabric.Object): fabric.IObjectOptions {
  const element: PageElement = this;

  if (!(element instanceof PageElement) || !(object instanceof fabric.Rect)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectOptions.call(element, object),
    width: element.width,
    height: element.height,
    scaleX: 1,
    scaleY: 1,
    fill: element.backgroundColor,
    selectable: false,
    rtl: (element.parent.hasRoundedCorners && element.borderRadius.leftTop) || 0,
    rtr: (element.parent.hasRoundedCorners && element.borderRadius.rightTop) || 0,
    rbr: (element.parent.hasRoundedCorners && element.borderRadius.rightBottom) || 0,
    rbl: (element.parent.hasRoundedCorners && element.borderRadius.leftBottom) || 0
  };
}

PageElement.prototype.getObjectControlsVisibility = function (object: fabric.Object): ControlsVisibility {
  const element: PageElement = this;

  if (!(element instanceof PageElement) || !(object instanceof fabric.Rect)) {
    return null;
  }

  return {
    ...BaseElement.prototype.getObjectControlsVisibility.call(element, object),
  };
}

PageElement.prototype.getCutThroughBehindPages = function (): PageElement[] {
  const element: PageElement = this;

  if (
    !(element instanceof PageElement) ||
    !element.pageBehindId ||
    !element.getCutThroughElements(false) ||
    !element.getCutThroughElements(true)
  ) {
    return [];
  }

  const behindPage = element.parent.pages.find((p) => p.id == element.pageBehindId);
  return [...behindPage.getCutThroughBehindPages(), behindPage];
}

PageElement.prototype.getCutThroughElements = function (inverted: boolean): CanvasElement[] {
  const element: PageElement = this;

  if (!(element instanceof PageElement)) {
    return [];
  }

  return element.children.filter((e) => inverted ? e.isCutThroughInverted : e.isCutThrough);
}

PageElement.prototype.getFoilElements = function (): CanvasElement[] {
  const element: PageElement = this;

  if (!(element instanceof PageElement)) {
    return [];
  }

  return getElements(element.children);

  function getElements(elements: CanvasElement[]): CanvasElement[] {
    return elements.filter((e) => e.isSpecialColorElement() && (e.foilType || getElements(e.children).length));
  }
}

PageElement.prototype.getFoilType = function (): FoilTypes {
  const element: PageElement = this;

  if (!(element instanceof PageElement)) {
    return null;
  }

  return getFoilType(element.children);

  function getFoilType(elements: CanvasElement[]): FoilTypes {
    return elements.reduce((foilType: FoilTypes, e) => foilType || (e.isSpecialColorElement() && e.foilType) || getFoilType(e.children), undefined);
  }
}

PageElement.prototype.getSpotUvElements = function (): CanvasElement[] {
  const element: PageElement = this;

  if (!(element instanceof PageElement)) {
    return [];
  }

  return getElements(element.children);

  function getElements(elements: CanvasElement[]): CanvasElement[] {
    return elements.filter((e) => e.isSpecialColorElement() && (e.spotUv || getElements(e.children).length));
  }
}
