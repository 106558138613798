import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TooltipPosition } from '@angular/material/tooltip';
import { Store, select } from '@ngrx/store';
import { round, uniq } from 'lodash-es';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { Editor } from 'slate-react';
import {
  ABSOLUTE_FONT_SIZE_SCALE,
  HorizontalAlignment
} from '../../../../../react-text-editor/models/text-editor.model';
import { CanvasActions } from '../../../actions';
import { Translate } from '../../../actions/canvas-actions';
import { Color, EditorSelection, Font, InlineTextElement, MarkTypes } from '../../../models';
import { IPoint } from '../../../models/ngresizable.interfaces';
import { AppState } from '../../../reducers';
import * as fromPermissions from '../../../reducers/permissions.reducer';
import { selectDesign } from '../../../selectors';
import { ConfigService, GetTextService, UiService } from '../../../services';
import { TextEditorService } from '../../../text-editor/text-editor.service';

import * as FabricCanvasActions from "../../design/canvas/actions";
import { InlineTextElementUtils } from '../../design/canvas/utils/inline-text-element.utils';

@Component({
  selector: 'ed-edit-inline-text',
  templateUrl: './edit-inline-text.component.html',
  styleUrls: ['../edit.component.scss', '../../../shared/button/buttons.scss', './edit-inline-text.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditInlineTextComponent implements OnInit, OnDestroy {
  public tooltipShowDelay$: Observable<number>;
  public tooltipPosition: TooltipPosition = 'below';

  lineHeightSteps = [0.85, 1, 1.15, 1.5, 2, 3];
  minLineHeight = 0;
  maxLineHeight = 10;
  useAlternativeInputInlineText: boolean;
  @Input() useAbsoluteFontSize = false;
  @Input() showMoreOptions = false;
  @Output() showMoreOptionsChange = new EventEmitter<boolean>();

  designFonts$: Observable<Font[]>;
  selectedFontScreenTitle: string;
  positioningTooltipText = this.getTextService.text.edit.order.tooltip;
  showDimensionsInputField$: Observable<boolean>;
  canEditSettings$: Observable<boolean>;
  horizontalAlignment = HorizontalAlignment;
  fontItemHeight = 42;
  markTypes = MarkTypes;
  protected _editFullRange: boolean;
  public get editFullRange(): boolean {
    return this._editFullRange;
  }

  public set editFullRange(value: boolean) {
    this._editFullRange = value;
  }

  dataLayerIdPrefix = 't-text-';

  protected _element: InlineTextElement;
  useRichText: boolean;
  editFullRangeBeforeChange = true;
  savedSelection: EditorSelection;
  currentSelection: EditorSelection;
  _inlineTextElement: InlineTextElement;
  fontLibrary: Font[];

  protected unsubscribe$ = new Subject<void>();

  @ViewChild(MatMenuTrigger, { static: false }) triggerLineHeightMenu: MatMenuTrigger;

  @Input()
  set element(element: InlineTextElement) {
    this._element = element;
    this._inlineTextElement = element;
    if (this.fontLibrary) {
      this.setSelectedFontScreenTitle();
    }

    this.editFullRange = element.editFullRange;
  }

  get element() {
    return this._element;
  }

  get editor(): Editor {
    return this.textEditorService.editor;
  }

  get inlineTextElement() {
    return this._inlineTextElement;
  }

  get boldSelected() {
    const boldUniqueValues = uniq(this.inlineTextElement.text.flatMap(p => p.lines.flatMap(l => l.textSpans.map(tp => tp.bold))));
    return boldUniqueValues.reduce((bold, value) => bold || value, false);
  }

  get italicSelected() {
    const italicUniqueValues = uniq(this.inlineTextElement.text.flatMap(p => p.lines.flatMap(l => l.textSpans.map(tp => tp.italic))));
    return italicUniqueValues.reduce((italic, value) => italic || value, false);
  }

  get underlinedSelected() {
    const underlinedUniqueValues = uniq(this.inlineTextElement.text.flatMap(p => p.lines.flatMap(l => l.textSpans.map(tp => tp.underlined))));
    return underlinedUniqueValues.reduce((underlined, value) => underlined || value, false);
  }

  get transparencySelected() {
    return Math.round((1 - this.inlineTextElement.text[0].lines[0].textSpans[0].opacity) * 100);
  }

  get textAlign() {
    const horizontalAlignUniqueValues = uniq(this.inlineTextElement.text.map(p => p.textAlign));
    return horizontalAlignUniqueValues.reduce((horizontalAlign, value) => horizontalAlign ?? value, undefined);
  }

  get horizontalAlign() {
    const horizontalAlignUniqueValues = uniq(this.inlineTextElement.text.map(p => p.isJustified || p.textAlign));
    return horizontalAlignUniqueValues.reduce((horizontalAlign, value) => horizontalAlign ?? value, undefined);
  }

  get lineHeight() {
    const lineHeightUniqueValues = uniq(this.inlineTextElement.text.map(p => p.lineHeight));
    return lineHeightUniqueValues.reduce((lineHeight, value) => lineHeight ?? value, undefined);
  }

  get fontFamily() {
    const fontFamilyValues = uniq(this.inlineTextElement.text.flatMap(p => p.lines.flatMap(l => l.textSpans.map(tp => tp.font))));
    return fontFamilyValues.reduce((fontFamily, value) => fontFamily ?? value, undefined);
  }

  get isJustified() {
    const isJustifiedValues = uniq(this.inlineTextElement.text.map(p => p.isJustified));
    return isJustifiedValues.reduce((isJustified, value) => isJustified || value, false);
  }

  get chipColor() {
    const colorUniqueValues = uniq(this.inlineTextElement.text.flatMap(p => p.lines.flatMap(l => l.textSpans.map(tp => tp.color))));
    const colorUniqueValue = colorUniqueValues.reduce((color, value) => color ?? value, undefined);

    const foilUniqueValues = uniq(this.inlineTextElement.text.flatMap(p => p.lines.flatMap(l => l.textSpans.map(tp => tp.foil))));
    const foilUniqueValue = foilUniqueValues.reduce((foil, value) => foil ?? value, undefined);

    if (foilUniqueValue) {
      return new Color('', colorUniqueValue, foilUniqueValue);
    }

    return colorUniqueValues.length > 1
      ? new Color('', '', this.element.foilType, this.element.spotUv)
      : new Color('', colorUniqueValue, this.element.foilType, this.element.spotUv);
  }

  get fontSizeScale() {
    return this.useAbsoluteFontSize ? 1 : ABSOLUTE_FONT_SIZE_SCALE;
  }

  get fontSize() {
    const fontSizeUniqueValues = uniq(this.inlineTextElement.text.flatMap(p => p.lines.flatMap(l => l.textSpans.map(tp => tp.fontSize))));
    return fontSizeUniqueValues.reduce((fontSize, value) => fontSize || value / this.fontSizeScale, 0);
  }

  get isResizing() {
    return this.uiService.isResizing;
  }

  setEditMode(editFullRange: boolean) {
    this.store.dispatch(new CanvasActions.SetInlineTextEditMode(this.element.route, editFullRange));
  }

  focusEditor(): void {
    if (this.config.isTouchDevice) {
      // happens on tablet landscape
      return;
    }

    this.editor?.focus();
  }

  blurEditor(): void {
    this.editor?.blur();
  }

  /**
   * take care of selection and focusing before applying operation
   * @param focus: whether or not the editor should focus after the operation
   * @param originalMethod:
   */
  protected _wrapOperation(focus: boolean, originalMethod: () => void) {
    if (!this.useRichText && !this.editFullRange) {
      // prevent `read-only` errors
      this.savedSelection = Object.assign({}, this.currentSelection);
      this.editFullRangeBeforeChange = false;

      // this combination is necessary to ensure textEditorService has most up-to-date selection but the selection is not visible.
      this.setEditMode(true);
      this.textEditorService.selectWholeText();
    }

    originalMethod();

    if (!this.useRichText && !this.editFullRangeBeforeChange) {
      if (focus) {
        this.savedSelection.isFocused = true;
      }
      this.setEditMode(false);
      this.editor?.select(this.textEditorService.convertToSlateSelection(this.savedSelection));
    } else if (this.useRichText && focus) {
      this.focusEditor();
    }
  }

  ngOnInit() {
    const config$ = this.store.pipe(select(s => s.config));
    this.tooltipShowDelay$ = config$.pipe(map(config => config.tooltipShowDelay));
    const designFontNames$ = this.store.pipe(
      select(selectDesign),
      map(d => d.designFonts)
    );
    this.store
      .pipe(
        select(i => i.fontlibrary.fontlibrary),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(fontLibrary => {
        this.fontLibrary = fontLibrary;
        if (this.element) {
          this.setSelectedFontScreenTitle();
        }
      });

    this.designFonts$ = combineLatest([designFontNames$, this.store.select(s => s.fontlibrary.fontlibrary)]).pipe(
      map(([designFonts, libFonts]) =>
        libFonts.filter(libFont => designFonts.find(designFont => libFont.name === designFont.name))
      )
    );

    this.store
      .pipe(select(fromPermissions.selectUseRichText), takeUntil(this.unsubscribe$))
      .subscribe(use => (this.useRichText = use));
    this.textEditorService.currentSelection$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selection => (this.currentSelection = selection));
    this.showDimensionsInputField$ = this.store.pipe(select(fromPermissions.getHasDimensionsInputField));
    this.canEditSettings$ = this.store.pipe(select(fromPermissions.canEditElementSettings));
    this.uiService.useAlternativeInputInlineText$
      .pipe(take(1))
      .subscribe(use => (this.useAlternativeInputInlineText = use));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  constructor(
    public getTextService: GetTextService,
    protected textEditorService: TextEditorService,
    protected store: Store<AppState>,
    protected config: ConfigService,
    public uiService: UiService
  ) { }

  toggleFontStyle(markType: string) {
    this._wrapOperation(true, () => {
      switch (markType) {
        case MarkTypes.Bold: {
          const nextFontWeight = InlineTextElementUtils.getTextboxFontWeight(!this.boldSelected);
          this.store.dispatch(new FabricCanvasActions.ChangeInlineTextFontWeight(this.element.route, nextFontWeight));
          break;
        }

        case MarkTypes.Italic: {
          const nextFontStyle = InlineTextElementUtils.getTextboxFontStyle(!this.italicSelected);
          this.store.dispatch(new FabricCanvasActions.ChangeInlineTextFontStyle(this.element.route, nextFontStyle));
          break;
        }

        case MarkTypes.Underlined: {
          const nextUnderline = !this.underlinedSelected;
          this.store.dispatch(new FabricCanvasActions.ChangeInlineTextUnderline(this.element.route, nextUnderline));
          break;
        }
      }
    });
  }

  changeTransparency(transparency: number) {
    this._wrapOperation(false, () => {
      this.store.dispatch(new FabricCanvasActions.ChangeElementOpacity(this.element.route, transparency));
    });
  }

  changeFontColor(color: Color) {
    this._wrapOperation(false, () => {
      if (!color || color === this.chipColor) {
        return;
      }

      if (color.foil || color.spotUv) {
        this.changeTransparency(0);
        this.store.dispatch(new CanvasActions.CheckSpecialColor(this.inlineTextElement.route, color));
        return;
      }
      else {
        this.store.dispatch(new CanvasActions.ChangeColor(this.inlineTextElement.route, color));
      }
    });
  }

  changeFontsize(value: number) {
    this._wrapOperation(true, () => {
      if (!isNaN(value)) {
        const nextFontSize = InlineTextElementUtils.getTextboxFontSize(value * this.fontSizeScale);
        this.store.dispatch(new FabricCanvasActions.ChangeInlineTextFontSize(this.element.route, nextFontSize));
      }
    });
  }

  rotate(rotation: number) {
    if (this.inlineTextElement.rotation !== rotation) {
      this.store.dispatch(
        new FabricCanvasActions.ChangeElementRotation(
          this.inlineTextElement.route,
          this.inlineTextElement.screenWidth,
          this.inlineTextElement.screenHeight,
          this.inlineTextElement.screenX,
          this.inlineTextElement.screenY,
          rotation
        )
      );
    }
  }

  flipHorizontal() {
    this.store.dispatch(
      new CanvasActions.FlipHorizontal(this.inlineTextElement.route, !this.inlineTextElement.flipHorizontal)
    );
  }

  flipVertical() {
    this.store.dispatch(
      new CanvasActions.FlipVertical(this.inlineTextElement.route, !this.inlineTextElement.flipVertical)
    );
  }

  translate(event: IPoint) {
    const screenX = this.inlineTextElement.x + event.x;
    const screenY = this.inlineTextElement.y + event.y;
    this.store.dispatch(
      new Translate(
        this.inlineTextElement.route,
        this.inlineTextElement.screenWidth,
        this.inlineTextElement.screenHeight,
        screenX,
        screenY
      )
    );
  }

  onInputLineHeight(value: string) {
    const convertedValue = Number(value);
    const validInput = convertedValue >= this.minLineHeight && convertedValue <= this.maxLineHeight;

    if (validInput) {
      const newLineHeight = round(convertedValue, 2);
      this.changeLineHeight(newLineHeight, false);
    }
  }

  onSelectLineHeight(event: MouseEvent, value: number) {
    this.changeLineHeight(value, true);

    /* stop propagation of event, to prevent that blur event of slate editor gets triggered after focus in
    changeLineHeight function is called. As a result of stopPropagation also the mat-menu is not getting closed, so its closed manually
    here */
    event.stopPropagation();
    this.triggerLineHeightMenu.closeMenu();
  }

  changeLineHeight(value: number, focus: boolean) {
    this._wrapOperation(focus, () => {
      const nextLineHeight = value;
      this.store.dispatch(new FabricCanvasActions.ChangeInlineTextLineHeight(this.element.route, nextLineHeight));
    });
  }

  changeHorizontalAlign(align: HorizontalAlignment) {
    this._wrapOperation(true, () => {
      if (align !== this.horizontalAlign) {
        const nextTextAlign = InlineTextElementUtils.getTextboxTextAlign(align, false);
        this.store.dispatch(new FabricCanvasActions.ChangeInlineTextTextAlign(this.element.route, nextTextAlign));
      }
    });
  }

  changeFontFamily(font: Font) {
    this._wrapOperation(false, () => {
      if (!font || font.name === this.fontFamily) {
        return;
      }

      const nextFontFamily = font.name;
      this.store.dispatch(new FabricCanvasActions.ChangeInlineTextFontFamily(this.element.route, nextFontFamily));
    });
  }

  justify() {
    this._wrapOperation(true, () => {
      if (!this.isJustified) {
        const nextTextAlign = InlineTextElementUtils.getTextboxTextAlign(null, true);
        this.store.dispatch(new FabricCanvasActions.ChangeInlineTextTextAlign(this.element.route, nextTextAlign));
      }
    });
  }

  removeElement() {
    this.store.dispatch(new CanvasActions.RemoveElement(this.element.route));
  }

  toggleMoreOptions() {
    this.showMoreOptions = !this.showMoreOptions;
    this.showMoreOptionsChange.emit(this.showMoreOptions);
  }

  setSelectedFontScreenTitle(): void {
    const selectedFont = this.fontLibrary.find(font => font.name === this.element.fonts[0]);
    this.selectedFontScreenTitle = selectedFont?.screenTitle || this.element.fonts[0];
  }
}
