import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { ImageComponent } from './image.component';
import { CanvasActions } from 'src/app/actions';
import { ImageData } from 'src/app/services/image-upload.service';
import { ImageElement, FunctionPermission, PhotoFrameElement } from '../../../models';
import { Store } from '@ngrx/store';
import { ConfigService, GetTextService } from '../../../services';
import { AppState } from '../../../reducers';

@Component({
  selector: 'ed-image-mob',
  templateUrl: 'image-mob.component.html',
  styleUrls: ['image-mob.component.scss', '../edit-mob.component.scss']
})
export class ImageMobComponent extends ImageComponent {
  @Input() imageIsSelected: boolean;
  @Input() imageElement: ImageElement | PhotoFrameElement;
  @Input() isLoggedIn: boolean;
  @Input() imageLibraryOpen = false;

  @Output() closeEditMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toggleImageLibrary: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.hide-edit-menu') displayEditMenu = false;

  showCloseButton = true;
  permission = FunctionPermission;

  constructor(public getTextService: GetTextService, protected store: Store<AppState>) {
    super(store);
  }

  chooseImage(event: any) {
    if (!this.imageElement) {
      return super.chooseImage(event);
    }

    this.store.dispatch(
      new CanvasActions.ReplaceImage(
        this.imageElement.route,
        event.width,
        event.height,
        event.width,
        event.height,
        event.sid,
        event.url,
        event.isFoilable
      )
    );
    this.closeImageLibrary();
  }

  uploadImageCallback() {
    if (!this.imageElement) {
      return super.uploadImageCallback();
    }

    return (event: ImageData) => {
      this.store.dispatch(
        new CanvasActions.ReplaceImage(
          this.imageElement.route,
          event.width,
          event.height,
          event.width,
          event.height,
          event.sid,
          "",
          false
        )
      );
      this.closeImageLibrary();
    };
  }

  closeEditMenu() {
    this.closeEditMode.emit(false);
  }

  closeImageLibrary() {
    this.toggleImageLibrary.emit(false);
  }
}
