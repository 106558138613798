import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'ed-button-container-mob',
  templateUrl: 'button-container-mob.component.html',
  styleUrls: ['button-container-mob.component.scss', '../../editor/edit/edit-mob.component.scss'],
})
export class ButtonContainerMobComponent {
  background: string = this.calcBackground(0);

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    const scrollValue = Math.floor(event.srcElement.scrollLeft / (event.srcElement.scrollWidth - event.srcElement.clientWidth) * 100);
    this.background = this.calcBackground(scrollValue);
  }

  private calcBackground(scrollValue: number) {
    const alpha = 0.5;
    const right = alpha - scrollValue / 100 * alpha;
    const left = scrollValue * alpha / 100;

    return [
      `linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 255, 255, ${left})) center left`,
      `linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, ${right})) center right`,
    ].join();
  }
}
