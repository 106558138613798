import { fabric } from "fabric";
import { cloneDeep } from "lodash-es";
import { isTinyObject } from "../../../utils/object.utils";

const size = 24;
const controlRadius = 6;

const img = document.createElement('img');
img.src = 'data:image/svg+xml,' + encodeURIComponent(`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <circle fill="#0008" cx="12" cy="12" r="7" />
    <circle fill="#FFF" cx="12" cy="12" r="6" stroke="#0008" stroke-width="0.2" stroke-opacity="0.7" />
  </svg>
`);

let isSideDrag: boolean;
let isMouseDown: boolean;
let controlPointX: number;
let controlPointY: number;
let offsetX = 0;
let offsetY = 0;

type ControlType = "tl" | "tr" | "br" | "bl";

const controlTypes: Record<ControlType, ControlType> = {
  tl: "tl",
  tr: "tr",
  br: "br",
  bl: "bl"
};

const Controls: Record<ControlType, fabric.Control> = {
  tl: cloneDeep(fabric.Object.prototype.controls.tl),
  tr: cloneDeep(fabric.Object.prototype.controls.tr),
  br: cloneDeep(fabric.Object.prototype.controls.br),
  bl: cloneDeep(fabric.Object.prototype.controls.bl)
};

Object.keys(Controls).forEach((control: ControlType) => {
  Object.assign(
    fabric.Object.prototype.controls[control],
    createPartialControl(control)
  );
});

function createPartialControl(controlType: ControlType): Partial<fabric.Control> {
  return {
    sizeX: 2 * controlRadius,
    sizeY: 2 * controlRadius,
    cursorStyleHandler: customCursorStyleHandler(controlType),
    actionHandler: customActionHandler(controlType),
    mouseDownHandler,
    getVisibility,
    render: customRender(controlType)
  }
}

function mouseDownHandler(eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean {
  isMouseDown = true;
  isSideDrag = false;
  return true;
}

function getVisibility(fabricObject: fabric.Object, controlKey: string): boolean {
  return !isTinyObject(fabricObject) && fabricObject._controlsVisibility[controlKey];
}


function customRender(controlType: ControlType) {
  return (ctx: CanvasRenderingContext2D, left: number, top: number, styleOverride: any, fabricObject: fabric.Object) => {
    const fabricControl = fabricObject.controls[controlType];

    let isTopOrBottomSide = controlType === controlTypes.tl || controlType === controlTypes.br;
    let isBottomControl = controlType === controlTypes.bl || controlType === controlTypes.br;

    //Calculate object size depending on scale (width or height)
    const objectSize = (isTopOrBottomSide ? fabricObject.getScaledWidth() : fabricObject.getScaledHeight()) * (fabricObject.canvas?.getZoom() ?? 1);

    //Check if render was called for Side or Corner
    const isSideRender = isTopOrBottomSide ? fabricControl.x === 0 : fabricControl.y === 0;

    const isSideScalable = !(fabricObject.data.downLg || fabricObject instanceof fabric.Textbox);

    //Controller direction relative to object center (x or y)
    const direction = isBottomControl ? 1 : -1;

    const x = isSideScalable ? 0 : direction * 0.5, y = x;
    const offsetX = isSideScalable ? direction * controlRadius : 0, offsetY = offsetX;
    const sizeX = isSideScalable ? (objectSize + controlRadius / 4) : 2 * controlRadius, sizeY = sizeX;

    Object.assign(fabricControl, isTopOrBottomSide
      ? { x, offsetX, sizeX }
      : { y, offsetY, sizeY }
    );

    const controlPoint = new fabric.Point(left, top);

    let renderLeft = left + (isSideRender && isTopOrBottomSide ? direction * (objectSize / 2 - controlRadius) : 0);
    let renderTop = top + (isSideRender && !isTopOrBottomSide ? direction * (objectSize / 2 - controlRadius) : 0);
    const renderPoint = new fabric.Point(renderLeft, renderTop);

    const { x: imgLeft, y: imgTop } = fabric.util.rotatePoint(renderPoint, controlPoint, fabric.util.degreesToRadians(fabricObject.angle));

    ctx.save();
    ctx.translate(imgLeft, imgTop);
    ctx.drawImage(img, -size / 2, -size / 2, size, size);
    ctx.restore();
  }
}

function customActionHandler(controlType: ControlType) {
  function calculateDistance(source: fabric.Point, origin: fabric.Point, angle: number): number {
    const radAngle = fabric.util.degreesToRadians(angle);
    const tangent = Math.tan(radAngle);
    return (tangent * (source.x - origin.x) - (source.y - origin.y)) / (Math.sqrt(tangent * tangent + 1));
  }

  return (eventData: MouseEvent, transformData: fabric.Transform, x: number, y: number): boolean => {
    const actionHandler = Controls[controlType].actionHandler;

    if (!(transformData.target.data.downLg || transformData.target instanceof fabric.Textbox)) {
      const controlPoint = transformData.target.aCoords[controlType];

      //Check if first mouse down on control
      if (isMouseDown) {
        isMouseDown = false;

        isSideDrag = !isCursorOnControl(new fabric.Point(x, y), transformData.target, controlType, controlRadius);

        controlPointX = controlPoint.x;
        controlPointY = controlPoint.y;

        if (!isSideDrag) {
          const centerPoint = transformData.target.getCenterPoint();
          const buffer = Math.sqrt(Math.pow(centerPoint.x - controlPointX, 2) + Math.pow(centerPoint.y - controlPointY, 2));

          //Offset initial scaling jump based on curson position on control
          offsetX = ((controlPointX - centerPoint.x) / buffer) * (controlRadius * 3 / 4) - x + controlPointX;
          offsetY = ((controlPointY - centerPoint.y) / buffer) * (controlRadius * 3 / 4) - y + controlPointY;
        } else {
          offsetY = 0;
          offsetX = 0;
        }
      }

      const originalControlPoint = new fabric.Point(controlPointX, controlPointY);
      const cursorPoint = new fabric.Point(x, y);
      const scaledWidth = transformData.target.width * transformData.target.scaleX;
      const scaledHeight = transformData.target.height * transformData.target.scaleY;

      let startCursorPoint: fabric.Point;

      if (isSideDrag) {
        if (controlType === controlTypes.tl || controlType === controlTypes.br) {
          const sideAngle = transformData.target.angle;
          const inverseDirection = sideAngle > 270 || sideAngle <= 90 ? 1 : -1;

          const dist = calculateDistance(cursorPoint, originalControlPoint, sideAngle);
          startCursorPoint = new fabric.Point(
            controlPointX - inverseDirection * dist * scaledWidth / scaledHeight,
            controlPointY - inverseDirection * dist
          );
        } else {
          const sideAngle = (transformData.target.angle + 90) % 360;
          const inverseDirection = sideAngle > 270 || sideAngle <= 90 ? 1 : -1;

          const dist = calculateDistance(cursorPoint, originalControlPoint, sideAngle);
          startCursorPoint = new fabric.Point(
            controlPointX + inverseDirection * dist,
            controlPointY - inverseDirection * dist * scaledHeight / scaledWidth
          );
        }

        const finalCursorPoint = fabric.util.rotatePoint(startCursorPoint, originalControlPoint, fabric.util.degreesToRadians(transformData.target.angle));
        x = finalCursorPoint.x;
        y = finalCursorPoint.y;
      }
    }

    return actionHandler(eventData, transformData, x + offsetX, y + offsetY);
  }
}

function customCursorStyleHandler(controlType: ControlType) {
  return (eventData: MouseEvent, control: fabric.Control, fabricObject: fabric.Object): string => {
    const cursorStyleHandler = Controls[controlType].cursorStyleHandler;
    const pointer = fabricObject.canvas.getPointer(eventData as Event);
    let angle = fabricObject.angle;
    let controlDummy = control;

    if (!(fabricObject.data.downLg || fabricObject instanceof fabric.Textbox)) {

      let cursorStyle: string;

      if (isCursorOnControl(new fabric.Point(pointer.x, pointer.y), fabricObject, controlType, controlRadius)) {
        angle -= 45;
      }

      return getCursorStyleByAngle(angle) + "-resize";
    }

    return cursorStyleHandler(eventData, controlDummy, fabricObject);

    function getCursorStyleByAngle(angle: number): string {
      const scaleMap = ['e', 'se', 's', 'sw', 'w', 'nw', 'n', 'ne', 'e'];
      return scaleMap[findCornerQuadrant(fabricObject, control, angle)];
    }

    function findCornerQuadrant(object: fabric.Object, control: fabric.Control, angle: number) {
      var cornerAngle = angle + fabric.util.radiansToDegrees(Math.atan2(control.y, control.x)) + 360;
      return Math.round((cornerAngle % 360) / 45);
    }
  }
}

function isCursorOnControl(pointerPosition: fabric.Point, object: fabric.Object, controlType: ControlType, threshold: number): boolean {
  const scaledThreshold = (4 + threshold) * (1 / object.canvas.getZoom());
  const controlPoint = object.aCoords[controlType];
  const distance = Math.sqrt(
    Math.pow(controlPoint.x - pointerPosition.x, 2) +
    Math.pow(controlPoint.y - pointerPosition.y, 2)
  );

  return distance <= scaledThreshold;
}
