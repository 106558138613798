import { X_PAGE_OVERLAY_BLEEDING_ROUTE, X_PAGE_OVERLAY_CUTTING_ROUTE, X_PAGE_OVERLAY_SAFETY_ROUTE, X_PAGE_OVERLAY_SAFETY_TEXT_ROUTE } from "../editor/design/canvas/fabric/constants/object-keys";

export type PageOverlayObjectTypes = "bleeding" | "safety" | "cutting" | "safety-text";

export const pageOverlayObjectTypes: Record<string, PageOverlayObjectTypes> = {
    BLEED: "bleeding",
    SAFETY: "safety",
    CUT: "cutting",
    SAFETY_TEXT: "safety-text",
};

export const pageOverlayObjectData: Record<PageOverlayObjectTypes, string> = {
    bleeding: X_PAGE_OVERLAY_BLEEDING_ROUTE,
    safety: X_PAGE_OVERLAY_SAFETY_ROUTE,
    cutting: X_PAGE_OVERLAY_CUTTING_ROUTE,
    "safety-text": X_PAGE_OVERLAY_SAFETY_TEXT_ROUTE,
};
