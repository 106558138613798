import { MarkJSON } from 'slate';

export interface Word {
  text: string;
  isWhiteSpace: boolean;
  key: string;
  x?: number;
  width?: number;
  marks?: MarkJSON[];
  parts?: Word[];
}

export const TEXTWIDTH_CACHE: { [wordPlusStyle: string]: number } = {};

export enum HorizontalAlignment {
  start = 'start',
  middle = 'middle',
  end = 'end'
}

export enum editStyleOption {
  text = 'text',
  font = 'font',
  fontSize = 'fontSize',
  fontColor = 'fontColor',
  fontStyle = 'fontStyle',
  lineHeight = 'lineHeight',
  transparency = 'transparency',
  horizontalAlign = 'horizontalAlign'
}

export enum editGeneralOption {
  rotate = 'rotate',
  flippable = 'flippable',
  order = 'order'
}

export const OLD_TEXT_MARGIN = 5;

export const POINTS_PER_MM = 2.8346;
export const POINTS_PER_PIXEL = 1 + (1 / 3);
export const ABSOLUTE_FONT_SIZE_SCALE = POINTS_PER_MM * POINTS_PER_PIXEL;

export const SVG_VIEWBOX_SCALE = POINTS_PER_MM;

export const DEFAULT_INLINE_TEXT_PADDING = 1;

export const INIT_ACTION = 'INIT';
export const SET_EDIT_RANGE = 'SET_EDIT_RANGE';
export const SET_MOVE_DESIGN_VERTICAL = 'SET_MOVE_DESIGN_VERTICAL';

export const TRANSFER_TYPE_FRAGMENT = 'fragment';
export const TRANSFER_TYPE_TEXT = 'text';
export const TRANSFER_TYPE_HTML = 'html';
