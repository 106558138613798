import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  HostBinding,
  OnDestroy
} from '@angular/core';
import { EditTextComponent } from './edit-text.component';
import { TextElement, Color } from '../../../models';
import { UiActions } from '../../../actions';

export type editOptionName =
  | 'text'
  | 'color'
  | 'font'
  | 'fontSize'
  | 'rotate'
  | 'flippable'
  | 'textAlign'
  | 'transparency'
  | 'order';

export class EditOption {
  public open = false;
  public enabled = true;

  constructor(public name: string) {}
}

export type EditOptions = { [E in editOptionName]: EditOption };

@Component({
  selector: 'ed-edit-text-mob',
  templateUrl: './edit-text-mob.component.html',
  styleUrls: ['../edit-mob.component.scss', './edit-text-mob.component.scss']
})
export class EditTextMobComponent extends EditTextComponent implements OnInit, OnDestroy {
  @Output() closeEditMode: EventEmitter<boolean> = new EventEmitter();
  @Output() textEditing: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('itemListMob', { static: false }) itemList: ElementRef;

  editOptions: EditOptions = {
    text: new EditOption('text'),
    color: new EditOption('color'),
    font: new EditOption('font'),
    fontSize: new EditOption('fontSize'),
    rotate: new EditOption('rotate'),
    flippable: new EditOption('flippable'),
    textAlign: new EditOption('textAlign'),
    transparency: new EditOption('transparency'),
    order: new EditOption('order')
  };

  _textElement: TextElement;

  @Input()
  set element(element: TextElement) {
    this.editOptions.color.enabled = element.permissions.isRecolorable;
    this.editOptions.font.enabled = element.permissions.fontChangeable;
    this.editOptions.fontSize.enabled = element.permissions.fontResizable;
    this.editOptions.rotate.enabled = element.permissions.isRotatable;
    this.editOptions.flippable.enabled = element.permissions.isFlippable;
    this.editOptions.transparency.enabled = element.permissions.adjustTransparency;
    this.editOptions.order.enabled = element.permissions.isOrderable;

    // close all editoptions when selecting another element
    if (!this.textElement || element.id !== this._textElement.id) {
      this.closeAllEditOptions();
    }

    if (element && element.isText()) {
      this._textElement = Object.assign(element);
    }

    // when a new text element is added, textNotEdited is true
    if (element.textNotEdited) {
      this.clearTextarea();
    }

    this.elementColor = new Color('', this.textElement.color, this.textElement.foilType);
  }

  @HostBinding('class.hide-edit-menu') displayEditMenu = false;

  clearTextarea() {
    if (this.textarea) {
      // use focus() and setSelectionRange() because select() does not work on iphone
      this.textarea.nativeElement.focus();
      this.textarea.nativeElement.setSelectionRange(0, this.textElement.text.length);
    }
  }

  get textElement() {
    return this._textElement;
  }

  ngOnInit() {
    this.initStoreSelections();

    if (this.textElement.textNotEdited) {
      this.openEditOption(this.editOptions.text);
    }
  }

  ngOnDestroy() {
    this.textEditing.emit(false);
  }

  get showAlternativeInputField() {
    return this.configService.showAlternativeInputField;
  }

  openSettingsMenu() {
    this.store.dispatch(new UiActions.ToggleSettingsMenu(true));
  }

  openEditOption(editOption: EditOption) {
    Object.keys(this.editOptions).map(name => (this.editOptions[name].open = name === editOption.name));

    // detect the appearance of textArea if text edit option was opened
    this.changeDetector.detectChanges();

    if (editOption === this.editOptions.text) {
      this.textEditing.emit(true);
    }

    if (this.textarea) {
      this.textarea.nativeElement.focus();
    }
  }

  closeAllEditOptions() {
    Object.keys(this.editOptions).map(name => (this.editOptions[name].open = false));
    this.textEditing.emit(false);
  }

  get optionSelected() {
    return Object.keys(this.editOptions).some(name => this.editOptions[name].open);
  }

  closeEditMenu() {
    this.closeEditMode.emit(false);
  }
}
